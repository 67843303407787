.footer {
    color: rgba(255, 255, 255, 0.5);
    background-color: #171A19;
    h5 {
        margin-bottom: 18px;
    }
    .btn {
        background-color: #fff;
        border-color: #fff;
        margin: 0 10px 10px 0;
        &:hover {
            background-color: #000;
        }
    }
    .btn--primary {
        background-color: @primary-color;
        border-color: @primary-color;
        &:hover {
            border-color: #fff;
        }
    }
}
.footer__logo {
    margin-bottom: 1rem;
    display: block;
}
.footer__main {
    .container;
    display: grid;
    font-size: 0.875rem;
    font-weight: 500;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    margin: 60px auto 40px;
    @media (min-width: 860px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 60px;
    }
    &__item--center {
        display: flex;
        @media (min-width: 860px) {
            justify-content: center;
        }
    }
}
.footer__meta {
    border-top: 1px solid #4F4F4F;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 8px 0 6px;
    line-height: 1.2;
    p {
        margin: 0 30px 0 0;
        text-transform: uppercase;
        flex: 1;
    }
    a {
        color: #fff;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        > div {
            margin-right: 30px;
        }
    }
}
.footer__sitemap {
    color: @primary-color;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 0 0 1rem;
    a {
        padding: 0 0 0.5rem;
        color: #fff;
        display: block;
        &:hover {
            opacity: 0.55;
        }
    }
}
.footer__contacts {
    list-style: none;
    margin: 0 0 40px;
    padding: 0;
    color: #fff;
    li {
        display: flex;
        // justify-content: center;
        align-items: center;
        margin-bottom: 1rem !important;
    }
    img {
        margin-right: 10px;
    }
    a {
        display: block;
        color: #fff;
    }
}
.footer__social {
    display: grid;
    display: inline-flex;
    grid-gap: 30px;
    grid-template-columns: auto auto auto;
    list-style: none;
    margin: 0;
    padding: 0;
    // color: #fff;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    a {
        color: #fff;
    }
}
