@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url('/static/fonts/montserrat-medium-webfont.woff2') format('woff2'),
        url('/static/fonts/montserrat-medium-webfont.woff') format('woff');

}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('/static/fonts/montserrat-semibold-webfont.woff2') format('woff2'),
        url('/static/fonts/montserrat-semibold-webfont.woff') format('woff');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('/static/fonts/montserrat-bold-webfont.woff2') format('woff2'),
        url('/static/fonts/montserrat-bold-webfont.woff') format('woff');
}
