.loader {
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
}
.loaderVisual {
    animation: load 1.1s infinite linear;
    border-bottom: 1.1em solid fade(#dad3d3, 20%);
    border-left: 1.1em solid fade(#dad3d3, 20%);
    border-right: 1.1em solid fade(#dad3d3, 20%);
    border-top: 1.1em solid fade(#272121, 20%);
    font-size: 5px;
    left: calc(~'50% - 5em');
    position: absolute;
    top: calc(~'50% - 5em');
}
.loaderVisual,
.loaderVisual:after {
    border-radius: 50%;
    height: 10em;
    width: 10em;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
