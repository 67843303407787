.section__title {
    font-size: 1.61875rem;
    text-align: center;
    margin: 0 0 40px;
    text-transform: uppercase;
    @media (min-width: @tablet) {
        margin: 0 0 60px;
    }
    a {
        color: @global-font-color;
        text-decoration: none;
        &:hover {
            color: @primary-color;
        }
    }
    .section--reportazhi &,
    .section--chitanka &,
    .section--lektorii &,
    .section--videos &,
    .section--photos & {
        color: #fff;
    }
}
.section__footer {
    text-align: center;
}

.section {
    padding: 50px 0 60px;
    @media (min-width: @tablet) {
        padding: 60px 0;
    }
}

// Section Livehistory
.sectionLivehistory {
    position: relative;
    color: #fff;
    text-align: center;
    padding: 80px 20px;
    &::after {
        background-color: rgba(#000, 60%);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    h2 {
        margin-bottom: 20px;
        font-weight: 400;
    }
    video {
        bottom: 0;
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: 50%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 0;
    }
    img {
        margin: 0 auto 30px;
        display: block;
    }
    p {
        margin-bottom: 30px;
    }
    a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
    .container {
        position: relative;
        z-index: 1;
    }
}

// Section Articles
// .section--articles {
    // .cards {
    //     margin-bottom: 20px;
    // }
// }
.section--intro {
    padding: 0;
    max-width: 1920px;
    margin: 0 auto;
}

.section--kolonki {
    .cards {
        margin-bottom: 40px;
    }
}
.section--statti {
    padding-top: 0;
    .cards {
        margin-bottom: 40px;
    }
}
.section--bez-bromu {
    padding-top: 0;
}
.section--lektorii {
    background-image: url('/static/images/backgrounds/lektoriy.jpg');
    background-size: cover;
    background-position: 50% 50%;
}

// Section Reports
.section--reportazhi {
    background-image: url('/static/images/bg_reportazh.jpg');
    background-size: cover;
    background-position: 50% 50%;
    // color: #fff;
    padding: 60px 0;
    .cards {
        margin-bottom: 40px;
    }
}
.section--interviu {
    .cards {
        margin-bottom: 40px;
    }
}
.section--chitanka {
    background-image: url('/static/images/bg_reportazh.jpg');
    background-size: cover;
    background-position: 50% 50%;
    // color: #fff;
    padding: 60px 0;
    .cards {
        margin-bottom: 40px;
    }
}


.section--videos {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/static/images/backgrounds/video.jpg');
    background-size: cover;
    background-position: 50% 50%;
    color: #fff;
}
.section--photos {
    background-image: url('/static/images/bg_album.jpg');
    background-size: cover;
    background-position: 50% 50%;
    color: #fff;
}

// Section Who we are
.section--whoWeAre {
    padding: 70px 0 40px;
    text-align: center;
    max-width: 860px;
    margin: auto;
    @media (min-width: @tablet) {
        padding: 120px 0;
    }
}

// Carousel
.section--carousel {
    min-height: 100%;
    @media (min-height: 660px) {
        height: 100%;
    }
}

// Services
.section--services {
    padding: 60px 0;
    background-color: #f8f8f8;
    @media (min-width: @tablet) {
        padding: 70px 0;
    }
}
.service__image {
    float: right;
    margin: 0 0 30px 30px;
}

// Cases
.section--cases {
    padding: 60px 0;
    @media (min-width: @tablet) {
        padding: 70px 0;
    }
}

// // Page Cases
// .filterForm {
//     margin: 0 0 60px;
//     text-align: center;
// }
// .filterFormItem {
//     display: flex;
//     font-weight: 600;
//     justify-content: center;
//     list-style: none;
//     margin: 0 0 30px;
//     padding: 0;
//     li {
//         margin: 0 12px;
//     }
//     label {
//         align-items: center;
//         cursor: pointer;
//         display: flex;
//         transition: all 0.2s;
//         &::before {
//             background-color: transparent;
//             border-radius: 50%;
//             content: "";
//             display: block;
//             height: 10px;
//             margin-right: 10px;
//             width: 10px;
//         }
//         &:hover {
//             color: @primary-color;
//         }
//     }
//     input {
//         display: none;
//         &:checked ~ label {
//             color: @primary-color;
//             &::before {
//                 background-color: @primary-color;
//             }
//         }
//     }
// }
// .filterFormButton {
//     html:not(.no-js) & {
//         display: none;
//     }
// }

// Page Case
.page__subtitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 18px;
}
.page__content {
    .container;
    margin-bottom: 30px;
    @media (min-width: @tablet) {
        margin-bottom: 60px;
    }
}
.case__description {
    text-align: center;
    margin-bottom: 30px;
    @media (min-width: @tablet) {
        margin-bottom: 60px;
    }
}
.page__imageVisual {
    margin-bottom: 30px;
    @media (min-width: @tablet) {
        margin-bottom: 60px;
    }
}
.page__image {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
}
.case__info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    @media (min-width: @tablet) {
        grid-gap: 60px;
        grid-template-columns: 3fr 2fr 7fr;
        justify-content: space-between;
    }
}
.case__services {
    padding: 0 0 0 18px;
    margin: 0;
}


.section--videos {
    overflow: hidden;
}

// Streamfields
.blockEmbed {
    margin: 0 0 30px;
    &--middle {
        max-width: 1000px;
        margin: 0 auto 30px;
    }
    &--preview {
        margin: 0 auto 60px;
    }
    iframe {
        display: block;
        margin: 0;
        min-height: 260px;
        width: 100%;
        @media (min-width: @tablet) {
            height: 60vh;
        }
    }
}
.blockSingleImage {
    margin: 0 0 30px;
    &--middle {
        max-width: 1000px;
        margin: 0 auto 30px;
    }
}
// .blockImages {
//     @media (min-width: @tablet) {
//         display: flex;
//         grid-gap: 16px;
//     }
//     &--middle {
//         max-width: 1000px;
//         margin: 0 auto 30px;
//     }
//     figure {
//         margin: 0 0 16px;
//         @media (min-width: @tablet) {
//             margin: 0;
//         }
//     }
// }
.blockImages {
    margin: 0 0 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    // display: block;
    // max-width: 100%;
    // height: auto;
    // object-fit: cover;
    // object-position: 50% 50%;
    &--middle {
        max-width: 1000px;
        margin: 0 auto 30px;
    }
    &--col2 {
        @media(min-width: @tablet) {
            grid-template-columns: 1fr 1fr;
        }
    }
    &--col3 {
        @media(min-width: @tablet) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &--col4 {
        @media(min-width: @tablet) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    figure {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
.blockImageVisual {
    position: relative;
}
.blockImage {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
}
.blockShareMe {
    .container;
    margin: 0 auto;
    list-style: none;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: @tablet) {
        .blockHero--background & {
            text-align: right;
            justify-content: flex-end;
        }
    }
    li {
        margin: 0 7px;
        @media (min-width: @tablet) {
            .blockHero--background & {
                margin: 0 0 0 15px;
            }
        }
    }
    a {
        display: block;
        opacity: 0.65;
        outline: none;
        &:hover {
            opacity: 1;
        }
    }
    img {
        display: block;
        body.dark &,
        .blockHero--background & {
            filter: invert(100%);
        }
    }
    .templateVideoItem & {
        justify-content: center;
        text-align: center;
    }
    .template--newsItem & {
        margin-bottom: 40px;
    }
}
.viewGallery {
    background-color: transparent;
    border: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    opacity: 0.65;
    &:hover {
        opacity: 1;
    }
    @media (min-width: @tablet) {
        margin: 0 25px 0 0;
    }
    img {
        display: block;
    }
}
.blockDate {
    color: #000;
    font-weight: 600;
    margin-bottom: 8px;
    .dark & {
        color: #fff;
    }
    span {
        color: #8e8e8e;
        font-weight: 400;
        transition: all .15s ease;
    }
}
.blockText {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1000px;
    max-width: 1000px;
    padding: 0 15px;
    @media (min-width: @tablet) {
        padding: 0 100px;
    }
    .subscriptionInfo & {
        max-width: 760px;
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
    .template--newsItem & {
        .container;
        box-sizing: initial;
        padding: 0;
        @media (min-width: @tablet) {
            padding: 0;
        }
    }
    iframe {
        width: 100%;
        display: block;
    }
    &--stylish {
        border-radius: 4px;
        margin: 0 15px 30px;
        max-width: 800px;
        padding: 30px 30px 0;
        &::after {
            content: ' ';
            display: block;
            width: 100%;
            clear: both;
            height: 1px;
        }
        .template--newsItem & {
            padding: 30px 30px 0;
            margin: 0 auto 30px;
            @media (min-width: @tablet) {
                padding: 30px 30px 0;
            }
        }
        @media (min-width: 768px) {
            margin: 0 100px 30px;
        }
        @media (min-width: 1000px) {
            margin: 0 auto 30px;
        }
    }
}
.blockTextImage {
    position: relative;
    margin: 0 0 30px;
    &.left {
        @media (min-width: @tablet) {
            float: left;
            margin: 0 30px 30px -100px;
            .template--newsItem & {
                margin: 0 30px 30px -30px;
            }
        }
    }
    &.right {
        @media (min-width: @tablet) {
            float: right;
            margin: 0 -100px 30px 30px;
            .template--newsItem & {
                margin: 0 0 30px 30px;
            }
        }
        @media (min-width: 1200px) {
            .template--newsItem & {
                margin: 0 -30px 30px 30px;
            }
        }
    }
    figcaption {
        max-width: 90%;
        margin: auto;
    }
}
.article__preview__link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    outline: none;
}

.tooltipBlock {
    background-image: url('/static/images/icons/info.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-block;
    height: 17px;
    position: relative;
    vertical-align: text-bottom;
    width: 17px;
    z-index: 1;
    .template--specProjects &,
    .dark & {
        filter: invert(100%);
        color: #000;
    }
    &__visual {
        background-color: #fff;
        border-radius: 2px;
        box-shadow:0 30px 90px -20px rgba(0,0,0,0.3), 0 0 1px 1px rgba(0,0,0,0.05);
        cursor: initial;
        display: none;
        font-size: 14px;
        left: 100%;
        margin-left: 10px;
        padding: 8px 14px 10px 10px;
        position: absolute;
        top: -10px;
        transition: all ease 0.35s;
        .template--specProjects &,
        .dark & {
            box-shadow:0 30px 90px -20px #fff, 0 0 1px 1px #fff;
        }
        .tooltipBlock:hover & {
            display: block;
            width: 200px;
        }
        &::after {
            border-color: transparent #fff transparent transparent;
            border-style: solid;
            border-width: 8px;
            content: "";
            position: absolute;
            right: 100%;
            top: 10px;
        }
        .left & {
            left: auto;
            margin-right: 10px;
            right: 100%;
            &::after {
                border-color: transparent transparent transparent #fff;
                left: 100%;
                right: auto;
            }
        }
    }
}

// Table
.blockTable {
    .container;
    overflow-x: auto;
}

// blockHero
.blockHero {
    box-sizing: border-box;
    margin: 0 0 40px;
    padding: 10px 0 40px;
    position: relative;
    // text-align: center;
    @media (min-width: @tablet) {
        margin: 0 0 60px;
    }
    &--background {
        color: #fff;
        @media (min-width: @tablet) {
            margin: 0 0 80px;
        }
        .template-searchresults & {
            background-image: url("/static/images/backgrounds/lektoriy.jpg");
            background-size: cover;
            background-position: 50% 0;
            background-repeat: no-repeat;
        }
        @media (min-width: @tablet) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            min-height: 600px;
            .template-searchresults & {
                min-height: 300px;
            }
        }
        &::after {
            background-color: fade(#000, 60%);
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            .template-searchresults & {
                display: none;
            }
        }
    }
    .template-rubricItem & {
        padding: 10px 0 0;
    }
    .template-searchresults & {
        margin: 0 0 15px;
        padding: 10px 0 40px;
        @media (min-width: @tablet) {
            margin-bottom: -60px;
            padding: 10px 0 120px;
        }
    }
    h1 {
        text-align: center;
    }
    p {
        text-align: center;
        margin-bottom: 40px;
    }
    .container {
        z-index: 1;
        position: relative;
    }
    .templateVideoItem & {
        margin: 0;
    }
}
.blockHero__image {
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.blockHeroNavigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 20px;
    max-width: 600px;
    p {
        margin: 0;
    }
}
.blockDescription {
    margin: 0 auto 60px;
    max-width: 1140px;
    @media (min-width: @tablet) {
        body:not(.template--newsItem):not(.template--gallery) & {
            display: grid;
            justify-content: space-between;
            align-items: center;
            grid-template-columns: auto 360px;
            grid-gap: 40px;
            margin: 0 auto 90px;
            &.alignCenter {
                grid-template-columns: auto;
                justify-content: center;
                text-align: center;
            }
        }
    }
    .template-specProjectPage &,
    .templateVideoItem &,
    .template-article & {
        padding: 0 15px;
    }

    .template-magazine & {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 40px;
    }
    .templateMagazineCollection & {
        margin-bottom: 60px;
        max-width: 760px;
    }
    .template--newsItem & {
        margin-bottom: 40px;
    }
    .template--gallery & {
        text-align: center;
    }
}
.blockDescription__visual {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 30px;
    @media (min-width: @tablet) {
        margin-bottom: 0;
    }
    .templateVideoIndex &,
    .templateVideoItem & {
        margin: auto;
        text-align: center;
    }
    .template--newsItem & {
        max-width: 100%;
    }
    p:last-child {
        margin-bottom: 0;
    }
}

.blockProjects {
    .container;
}

.blockAuthors {
    margin: 0 auto;
    width: 100%;
    // display: flex;
    // justify-content: center;
    // @media (min-width: @tablet) {
    //     max-width: 32%;
    // }
}
.blockAuthors__item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    text-align: left;
    position: relative;
    padding: 10px 15px 10px 5px;
    border-radius: 10px;
    &:hover {
        background-color: #eee;
        .dark & {
            background-color: #000;
        }
    }
    h4 {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        @media (min-width: @tablet) {
            margin: 0 0 10px;
        }
        .dark & {
            color: #fff;
        }
    }
}
.blockAuthors__item__img {
    display: block;
    margin: 0 15px 0 0;
    border-radius: 50%;
    border: 3px solid #fff;
    width: 60px;
    height: 60px;
    @media (min-width: @tablet) {
        margin-right: 25px;
        width: 110px;
        height: 110px;
    }
    .blockAuthors__item:hover & {
        border-color: @primary-color;
    }
    .authorHeader & {
        margin: 0 auto 15px;
        width: 150px;
        height: 150px;
    }
}
.blockAuthors__item__job {
    margin: 0;
    color: #000;
    // font-size: 18px;
    line-height: 22px;
    .dark & {
        color: #fff;
    }
}

.blockTags {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 60px auto;
    max-width: 1000px;
    padding: 0 15px;
    flex-wrap: wrap;
    clear: both;
}
.blockTag__link {
    background-color: #9e9e9e;
    border-radius: 3px 0 0 3px;
    color: #fff;
    display: block;
    font-size: 14px;
    height: 26px;
    line-height: 26px;
    margin: 0 10px 10px 0;
    padding: 0 20px 0 23px;
    position: relative;
    text-transform: uppercase;
    &::before {
        background-color: #f4f4f4;
        border-radius: 50%;
        content: '';
        height: 6px;
        left: 10px;
        position: absolute;
        top: 10px;
        width: 6px;
    }
    &::after {
        background-color: #f4f4f4;
        border-bottom: 13px solid transparent;
        border-left: 10px solid #9e9e9e;
        border-top: 13px solid transparent;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.2s;
        body.dark & {
            background-color: fade(#000, 90%);
        }
    }
    &:hover {
        background-color: @primary-color;
        &::after {
            border-left: 10px solid @primary-color;
        }
    }
}

.sliderVisual {
    box-sizing: border-box;
    margin: 0 auto 30px;
    max-width: 1000px;
    padding: 0;
    position: relative;
}
.blockSlider__item {
    position: relative;
}

// List News
.listNews {
    list-style: none;
    margin: 0 auto 30px;
    max-width: 920px;
    padding: 0;
    @media (min-width: @tablet) {
        margin-bottom: 40px;
    }
    .section--news & {
        display: grid;
        grid-template-columns: 1fr;
        @media (min-width: @tablet) {
            grid-gap: 10px 60px;
            grid-template-columns: 1fr 1fr;
        }
    }
}
.listItem {
    padding: 0;
    margin: 0 0 30px;
    position: relative;
    .template--newsCollection & {
        background-image: url("/static/images/icons/bullet.svg");
        background-position: 0 19px;
        background-repeat: no-repeat;
        padding: 15px 15px 15px 30px;
        margin: 0;
    }
}
.listItem__date {
    font-size: 14px;
    line-height: 17px;
    transition: all .15s ease;
    .listItem:hover & {
        color: @primary-color;
        span {
            color: @primary-color;
        }
    }
}
.listItem__title {
    // line-height: 1.4;
    margin: 0 0 6px;
    transition: all .15s ease;
    font-weight: 600;
    .listItem:hover & {
        color: @primary-color;
    }
}
.listItem__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

// Magazine Page
.magazineBody {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;
    margin-bottom: 40px;
    @media (min-width: @tablet) {
        grid-template-columns: 2fr 3fr;
        margin-bottom: 60px;
    }
    .blockText {
        padding: 0;
    }
}
.magazineBody__preview {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.magazineBody__preview__cover {
    background-color: #fff;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05) !important;
    box-sizing: border-box;
    display: block;
    height: auto;
    margin-bottom: 5px;
    max-width: 100%;
    padding: 15px;
}
.magazineBody__preview__gallery {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
    max-width: 466px;
}
.magazineBody__preview__link {
    display: block;
}
.magazineBody__preview__image {
    display: block;
    height: auto;
    max-width: 100%;
}
.magazineBody__info {
    display: none;
    @media (min-width: @tablet) {
        display: block;
    }
    &Mobile {
        @media (min-width: @tablet) {
            display: none;
        }
    }
}
.magazineBodyOrder {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    &__price {
        font-size: 1.61875rem;
        font-weight: bold;
    }
}
.magazineBodyStatus {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    display: inline-flex;
    font-size: 12px;
    &::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: block;
        margin-right: 10px;
        background-color: #ccc;
    }
    &.selling {
        background-color: #34bd03;
        color: #fff;
        &::before {
            background-color: #fff;
        }
    }
    &.soldout {
        background-color: #aaa;
        color: #fff;
        &::before {
            background-color: #fff;
        }
    }
    &.expectation {
        background-color: #f9c807;
        color: #fff;
        &::before {
            background-color: #fff;
        }
    }
    .carouselMagazine & {
        position: absolute;
        top: 15px;
        left: 0;
    }
    .cardsMagazine & {
        position: absolute;
        top: 15px;
        left: 0;
    }
}

// Subscription Page
.subscriptionInfo {
    background-color: #000;
    background-image: url('/static/images/backgrounds/subscription.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    color: #fff;
    position: relative;
    padding: 10px 0 60px;
    &::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.07%, #000000 90.4%), linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        content: "";
    }
    &::after {
        content: "";
        display: table;
        clear: both;
        width: 100%;
    }
    .card {
        color: #000;
    }
    .blockText,
    .sectionCards {
        z-index: 1;
        position: relative;
    }
    h2 {
        text-align: center;
    }
    .sectionCards {
        max-width: 760px;
        padding: 0 15px;
        margin: 0 auto 50px;
    }
}

// Search Page
.searchForm {
    @media (min-width: @tablet) {
        display: flex;
        flex-wrap: wrap;
    }
}
.searchFormWrapper {
    margin: 0 2px 0 0;
    @media (min-width: @tablet) {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        flex-wrap: wrap;
    }
}
.searchFormInput {
    border: 0;
    box-sizing: border-box;
    font-family: @sans-serif-fonts;
    font-size: 18px;
    line-height: 1;
    margin: 0 0 2px;
    outline: 0;
    padding: 7px 15px 8px;
    width: 100%;
    @media (min-width: @tablet) {
        margin: 0 2px 0 0;
        min-width: 350px;
        width: auto;
    }
}
.searchFormButton {
    align-items: center;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    display: flex;
    margin: auto;
    padding: 6px 12px 8px;
    transition: 0.1s ease;
    &:hover {
        background-color: @primary-color;
        color: #fff;
    }
    span {
        margin-left: 7px;
        @media (min-width: @tablet) {
            display: none;
        }
    }
    svg {
        display: block;
        height: auto;
        max-width: 20px;
    }
}
.searchResultsCount {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    @media (min-width: @tablet) {
        color: fade(#fff, 80%);
        margin-bottom: 0;
        padding: 0 30px;
    }
    strong {
        font-size: 16px;
        @media (min-width: @tablet) {
            color: #fff;
        }
    }
}

// Home page
.sectionReport {
    overflow: hidden;
    padding: 60px 0;
    position: relative;
    @media (min-width: 1025px) {
        padding: 90px 0;
    }
    .container {
        @media (min-width: 1025px) {
            align-items: flex-start;
            display: grid;
            grid-gap: 60px;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
        }
    }
}
.sectionReport__background {
    background-image: url("/static/images/bg_reportazh.jpg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(45%) blur(10px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.sectionReport__brightness {
    background-color: #555;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
}
.sectionReport__preview {
    color: #fff;
    display: none;
    font-weight: normal;
    @media (min-width: 1025px) {
        display: block;
    }
    h2 {
        font-size: 35px;
        line-height: 47px;
        text-transform: uppercase;
    }
    a {
        background-color: fade(#fff, 50%);
        color: #000;
        font-size: .875rem;
        padding: 10px 20px;
        text-transform: uppercase;
    }
}
.sectionReport__preview__tags {
    background-color: #efac00;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    margin: 0 0 30px;
    padding: 2px 10px;
    text-transform: uppercase;
    z-index: 1;
}
.sectionReport__content {
    .section__title {
        color: #fff;
        @media (min-width: 1025px) {
            display: none;
        }
    }
    .btn {
        border-color: #fff;
        color: #fff;
        display: block;
        margin: auto;
        text-transform: uppercase;
        width: 150px;
        &:hover {
            background-color: #fff;
            color: #000;
        }
    }
}
.sectionMicrorubric:nth-child(2n) {
    background-color: #eee;
}

// Cookies message
.cookies {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1055;
    padding: 15px;
    background-color: fade(#fff, 85%);
    backdrop-filter: blur(2px);
}
.cookies__visual {
    background-color: #000;
    color: #fff;
    max-width: 500px;
    margin: auto;
    // overflow: auto;
    max-height: 80%;
    position: relative;
    padding: 15px;
    @media (min-width: @tablet) {
        display: flex;
        padding: 30px;
    }
}
.cookies__image {
    max-width: 100px;
    height: auto;
    float: left;
    margin: 0 15px 15px 0;

    display: block;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.19);
    @media (min-width: @tablet) {
        max-width: 260px;
        float: none;
        margin: 0 30px 0 -130px;
    }
}
.btnCookieClose {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 28px;
    cursor: pointer;
    border: 0;
    background-color: #000;
    @media (min-width: @tablet) {
        top: 15px;
        right: 15px;
    }
}
.cookie__info {
    @media (min-width: @tablet) {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .magazineBodyStatus {
        background-color: transparent;
        margin-bottom: 20px;
        padding: 0;
        &.selling::before {
            background-color: #34bd03;
        }
        &.expectation::before {
            background-color: #f9c807;
        }
    }
}
.cookie__header {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 15px;
    text-transform: uppercase;
    @media (min-width: @tablet) {
        font-size: 30px;
        margin: 0;
    }
    span {
        display: block;
        font-weight: normal;
    }
}
.cookie__body {
    clear: both;
}
.cookie__title {
    margin-bottom: 0;
    line-height: 1;
}
.cookie__subtitle {
    margin-bottom: 15px;
}
.cookie__magazinePrice {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 15px;
    @media (min-width: @tablet) {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

// authorHeader
.authorHeader {
    text-align: center;
    margin: 0 0 60px;
    h1 {
        margin: 0 0 20px;
    }
}
