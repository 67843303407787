.tns-outer {
    padding: 0 !important;
    [hidden] {
        display: none !important;
    }
    [aria-controls],
    [data-action] {
        cursor: pointer;
    }
}
.tns-slider {
    transition: all 0s;
    &::after,
    &::before {
        content: '';
        width: 100%;
        clear: both;
        display: table;
    }
}

.tns-slider>.tns-item {
    box-sizing: border-box
}

.tns-horizontal.tns-subpixel {
    white-space: nowrap
}

.tns-horizontal.tns-subpixel>.tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal
}

.tns-horizontal.tns-no-subpixel:after {
    content: '';
    display: table;
    clear: both
}

.tns-horizontal.tns-no-subpixel>.tns-item {
    float: left
}

.tns-horizontal.tns-carousel.tns-no-subpixel>.tns-item {
    margin-right: -100%
}

.tns-no-calc {
    left: 0;
    position: relative;
}
.tns-gallery {
    left: 0;
    min-height: 1px;
    position: relative;
}
.tns-gallery>.tns-item {
    left: -100%;
    position: absolute;
    transition: transform 0, opacity 0;
}
.tns-gallery>.tns-slide-active {
    left: auto !important;
    position: relative;
}
.tns-gallery>.tns-moving {
    transition: all 0.25s;
}
.tns-autowidth {
    display: inline-block
}
.tns-lazy-img {
    opacity: 0.6;
    transition: opacity 0.6s;
}
.tns-lazy-img.tns-complete {
    opacity: 1;
}
.tns-ah {
    transition: height 0;
}
.tns-ovh {
    overflow: hidden;
}
.tns-visually-hidden {
    left: -10000em;
    position: absolute;
}
.tns-transparent {
    opacity: 0;
    visibility: hidden;
}
.tns-fadeIn {
    opacity: 1;
    z-index: 0;
}
.tns-normal,
.tns-fadeOut {
    opacity: 0;
    z-index: -1;
}
.tns-vpfix {
    white-space: nowrap;
    & >li {
        display: inline-block;
    }
    & > div {
        display: inline-block;
    }
}
.tns-t-subp2 {
    height: 10px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 310px;
}
.tns-t-ct {
    position: absolute;
    right: 0;
    width: calc(100% * 70 / 3);
    &::after {
        clear: both;
        content: '';
        display: table;
    }
    & > div {
        float: left;
        height: 10px;
        width: calc(100% / 70);
    }
}
.tns-nav {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    button {
        background-color: rgb(224, 224, 224);
        border: 0;
        cursor: pointer;
        height: 4px;
        margin: 0 2px;
        overflow: hidden;
        width: 36px;
        &.tns-nav-active {
            background-color: @primary-color;
        }
    }
}

// Carousel
.carouselVisual {
    position: relative;
    .templateSubscription & {
        padding: 60px 0;
    }
}
.carouselMagazine {
    margin: 0 0 40px;
}
.carouselVideos {
    margin: 0 0 10px;
}
.carouselPhotos {
    margin: 0 0 15px;
}
.carouselHero {
    background-color: fade(#fff,60%);
    display: flex;
    flex-wrap: wrap;
}

.carousel__item {
    position: relative;
    .carouselMagazine & {
        text-align: center;
    }
}
.carousel__itemVisual {
    position: relative;
        .carouselVideos & {
            @media (min-width: @tablet) {
                max-width: 800px;
                transition: all .15s ease;
                filter: blur(0.08rem) brightness(75%) saturate(25%);
                &:hover {
                    filter: none;
                }
            }
        }
        .carouselPhotos & {
            @media (min-width: @tablet) {
                max-width: 440px;
                transition: all .15s ease;
                filter: blur(0.08rem) brightness(75%) saturate(25%);
                &:hover {
                    filter: none;
                }
            }
        }
        @media (min-width: @tablet) {
            .carouselPhotos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active &,
            .carouselVideos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active & {
                filter: none;
            }
        }
        @media (min-width: 1000px) {
            .carouselPhotos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active &,
            .carouselPhotos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active & {
                filter: none;
            }
        }
        @media (min-width: 1200px) {
            .carouselVideos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active &,
            .carouselVideos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active & {
                filter: none;
            }
        }
        .carouselHero & {
            overflow: hidden;
            height: 100%;
            position: relative;
            @media (min-width: 960) {
                min-height: 390px;
            }
            @media (min-width: 1300px) {
                height: 560px;
            }
            &:not(.slideVisual) {
                @media (min-width: 960px) {
                    display: grid;
                    grid-gap: 30px;
                    grid-template-columns: 1fr 1fr;
                }
            }
            &:hover {
                background-color: fade(#fff, 50%);
                .btn {
                    background-color: fade(@primary-color, 75%);
                }
            }
        }
}


.carousel__item__text {
    .carouselMagazine & {
        margin: 0 10px 15px;
    }
    &--price {
        font-size: 18px;
        font-weight: 600;
    }
    .carouselHero & {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        margin: 0 auto;
    }
}
.carousel__item__img__wrap {
    .carouselMagazine & {
        margin: 0 0 15px;
    }
    .carouselHero .carousel__itemVisual:not(.slideVisual) & {
        @media (min-width: 960px) {
            align-items: center;
            display: flex;
            justify-content: center;
            overflow: hidden;
            position: relative;
        }
    }
}
.carousel__item__image {
    display: block;
    height: auto;
    width: 100%;
    .carouselHero & {
        @media (min-width: 960px) {
            max-height: 100%;
            max-width: 100%;
            width: auto;
        }
    }
    .carouselHero .slideVisual & {
        @media (min-width: 960px) {
            bottom: 0;
            display: block;
            filter: brightness(45%);
            height: 100%;
            left: 0;
            object-fit: cover;
            object-position: 50% 50%;
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.3s ease-out;
            width: 100%;
        }
    }
}
.carousel__item__body {
    .carouselHero & {
        padding: 15px 15px 35px;
        @media (min-width: 960px) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 30px 30px 30px 62px;
        }
        @media (min-width: 1040px) {
            padding: 62px 120px 62px 62px;
        }
    }
    .carouselHero .slideVisual & {
        @media (min-width: 960px) {
            box-sizing: border-box;
            color: #fff;
            height: 100%;
            margin: auto;
            max-width: 1264px;
            position: relative;
            text-align: center;
            z-index: 2;
            .btn {
                margin: auto;
            }
        }
        @media (min-width: 1040px) {
            padding: 62px;
        }
    }
}
.carousel__item__type {
    .carouselHero & {
        background-image: url("/static/images/icons/bullet.svg");
        background-position: 0 4px;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        padding: 0 15px 0 25px;
    }
}
.carousel__item__title {
    .carouselHero & {
        font-size: 28px;
        text-transform: none;
        @media (min-width: @tablet) {
            font-size: 42px;
        }
    }
    .carouselVideos & {
        text-align: center;
        padding: 20px 15px;
        @media (min-width: @tablet) {
            padding: 20px 30px;
        }
    }
    .carouselPhotos & {
        text-align: center;
        padding: 20px 15px 5px;
        margin-bottom: 0;
        @media (min-width: @tablet) {
            padding: 20px 30px 5px;
        }
    }
}
.carousel__item__count {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #828282;
    text-align: center;
}
.carousel__item__link {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    .carouselMagazine & {
        right: 30px;
        &:hover {
            backdrop-filter: blur(2px);
            background-color: fade(#fff, 80%);
        }
    }
}
.carousel__item__linkVisual {
    display: none;
    .carousel__item__link:hover & {
        background-color: @primary-color;
        color: #fff;
        display: block;
        font-size: 18px;
        line-height: 1;
        padding: 10px 15px;
    }
}


// Prev/Next Navigation
.slider-prev {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    left: 0;
    outline: none;
    padding: 15px;
    position: absolute;
    top: 30%;
    z-index: 1;
    @media (min-width: 960px) {
        padding: 15px 30px;
    }
    // &:hover {
    //     backdrop-filter: blur(2px);
    //     background-color: fade(#fff, 50%);
    // }
    .section--intro & {
        top: 0;
        left: auto;
        right: 50px;
        @media (min-width: 960px) {
            right: auto;
            top: calc(50% - 32px);
        }
    }
    .section--magazines &,
    .templateSubscription & {
        @media (min-width: 1340px) {
            background-color: transparent;
            left: auto;
            // margin-right: 15px;
            right: 100%;
        }
    }
    .sliderVisual & {
        top: 35%;
        // left: 15px;
        // @media (min-width: @tablet) {
        //     left: 30px;
        // }
    }
    .section--photos &,
    .section--videos & {
        @media (min-width: @tablet) {
            left: 30px;
        }
    }
    img {
        transform: rotate(180deg);
    }
}
.slider-next {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 30%;
    z-index: 1;
    outline: none;
    @media (min-width: 960px) {
        padding: 15px 30px;
    }
    // &:hover {
    //     backdrop-filter: blur(2px);
    //     background-color: fade(#fff, 50%);
    // }
    .section--intro & {
        top: 0;
        @media (min-width: 960px) {
            top: calc(50% - 32px);
        }
    }
    .sliderVisual & {
        top: 35%;
        // right: 15px;
        // @media (min-width: @tablet) {
        //     right: 30px;
        // }
    }
    .section--magazines &,
    .templateSubscription & {
        @media (min-width: 1340px) {
            background-color: transparent;
            left: 100%;
            // margin-left: 15px;
            right: auto;
        }
    }
    .section--photos &,
    .section--videos & {
        @media (min-width: @tablet) {
            right: 30px;
        }
    }
}


// .owl-carousel {
//     -webkit-tap-highlight-color: transparent;
//     display: none;
//     position: relative;
//     width: 100%;
//     z-index: 1;
//     height: 100%;
//     &.owl-loaded {
//         display: block;
//     }
//     &.owl-loading {
//         display: block;
//         opacity: 0;
//     }
//     &.owl-hidden {
//         opacity: 0;
//     }
//     &.owl-grab {
//         cursor: grab;
//     }
//     &.owl-rtl {
//         direction: rtl;
//     }
//     .no-js & {
//         display: block;
//     }
// }
// .owl-stage {
//     -ms-touch-action: pan-Y;
//     backface-visibility: hidden;
//     position: relative;
//     touch-action: manipulation;
//     display: flex;
//     min-height: 100%;
//     &:after {
//         clear: both;
//         content: ".";
//         display: block;
//         height: 0;
//         line-height: 0;
//         visibility: hidden;
//     }
// }
// .owl-stage-outer {
//     overflow: hidden;
//     position: relative;
//     transform: translate3d(0px, 0px, 0px);
//     height: 100%;
// }
// .owl-wrapper {
//     transform: translate3d(0, 0, 0);
// }
// .owl-item {
//     -webkit-tap-highlight-color: transparent;
//     -webkit-touch-callout: none;
//     backface-visibility: hidden;
//     // float: left;
//     // min-width: 100vw !important;
//     min-height: 100%;
//     position: relative;
//     transform: translate3d(0, 0, 0);
//     .owl-rtl & {
//         float: right;
//     }
//     .owl-refresh & {
//         visibility: hidden;
//     }
//     .owl-drag & {
//         touch-action: pan-y;
//         user-select: none;
//     }
// }
// .carousel__item {
//     background-color: currentColor;
//     padding: 0 60px;
//     width: 100vw;
//     box-sizing: border-box;
//     min-height: 100%;
//     @media (min-width: @largeDesktop) {
//         padding: 0;
//     }
// }
// .carousel__itemVisual {
//     align-items: center;
//     box-sizing: border-box;
//     display: grid;
//     grid-gap: 60px;
//     grid-template-columns: 1fr;
//     padding: 90px 0 40px;
//     // padding: 85px 0 120px;
//     @media (min-width: @tablet) {
//         padding: 90px 0;
//         grid-template-columns: 400px auto;
//         height: 100vh;
//     }
// }
// .carousel__item__content {
//     text-align: center;
//     @media (min-width: @tablet) {
//         text-align:  left;
//     }
// }
// .carousel__item__title {
//     color: #fff;
//     font-size: 28px;
//     line-height: 1.05;
//     @media (min-width: @tablet) {
//         font-size: 48px;
//     }
//     a {
//         color: #fff;
//         text-decoration: none;
//     }
// }
// .carousel__item__description {
//     color: #fff;
// }
// .carousel__item__image {
//     display: block;
//     height: auto;
//     margin: auto;
//     max-height: 575px;
//     max-width: 100%;
//     width: auto;
//     @media (min-width: @tablet) {
//         margin-right: 0;
//     }
// }
// .carousel__item__link {
//     border-bottom: 1px solid #fff;
//     color: #fff;
//     padding: 5px 2px;
//     text-decoration: none;
//     @media (min-width: @tablet) {
//         clear: both;
//         display: inline-block;
//         margin: 30px 0 0;
//     }
//     &:hover {
//         background-color: #fff;
//         color: currentColor;
//         padding-left: 20px;
//         padding-right: 20px;
//         // @media (min-width: @tablet) {
//         //     margin-left: -20px;
//         // }
//     }
// }

// // Carousel Navigation
// .owl-nav.disabled,
// .owl-dots.disabled {
//     display: none;
// }
// .owl-prev {
//     left: 0;
//     opacity: 0.5;
//     padding: 20px;
//     position: absolute;
//     top: calc(~'50% - 40px');
//     @media (min-width: @largeDesktop) {
//         left: 30px;
//         padding: 5px;
//     }
//     &:hover {
//         opacity: 1;
//     }
//     img {
//         transform: rotate(180deg);
//     }
// }
// .owl-next {
//     opacity: 0.5;
//     padding: 20px;
//     position: absolute;
//     right: 0;
//     top: calc(~'50% - 40px');
//     @media (min-width: @largeDesktop) {
//         padding: 5px;
//         right: 30px;
//     }
//     &:hover {
//         opacity: 1;
//     }
// }
// .owl-prev,
// .owl-next {
//     cursor: pointer;
//     user-select: none;
// }
// .owl-nav button.owl-prev,
// .owl-nav button.owl-next,
// button.owl-dot {
//     background: none;
//     border: 0;
//     color: inherit;
//     font: inherit;
//     padding: 0 !important;
// }

// // Carousel Dots
// .owl-dots {
//     align-items: center;
//     bottom: 30px;
//     cursor: pointer;
//     display: flex;
//     justify-content: center;
//     left: 0;
//     position: absolute;
//     right: 0;
//     user-select: none;
//     z-index: 233444;
//     @media (min-width: @tablet) {
//         bottom: 60px;
//     }
// }
// .owl-dot {
//     padding: 6px;
//     span {
//         background-color: fade(#fff, 40%);
//         display: block;
//         height: 4px;
//         width: 38px;
//     }
//     &.active {
//         span {
//             background-color: #fff;
//         }
//     }
// }

// // Carousel - Animate Plugin
// .owl-carousel {
//     .animated {
//         animation-duration: 1000ms;
//         animation-fill-mode: both;
//     }
//     .owl-animated-in {
//         z-index: 0;
//     }
//     .owl-animated-out {
//         z-index: 1;
//     }
//     .fadeOut {
//         animation-name: fadeOut;
//     }
// }
// @keyframes fadeOut {
//     0% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0;
//     }
// }

// // Carousel - Auto Height Plugin
// .owl-height {
//     transition: height 500ms ease-in-out;
// }
