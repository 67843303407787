// Breadcrumbs
.breadcrumbs {
    .container;
    box-sizing: border-box;
    margin: 0 auto 30px;
    position: relative;
    width: 100%;
    z-index: 1;
    @media (min-width:@tablet) {
        margin: 0 auto 40px;
    }
    @media (min-width: 1230px) {
        box-sizing: initial;
    }
    body.dark &,
    .blockHero--background & {
        color: #fff;
    }
}
.breadcrumb__items {
    display: inline-block;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    svg {
        margin: 0 5px;
    }
}
.breadcrumb__items li {
    display: inline-block;
    vertical-align: middle;
}
.breadcrumb__items a {
    color: fade(#000, 40%);
    text-decoration: none;
    &:hover {
        color: #000;
    }
    body.dark &,
    .template--specProjects &,
    .templateSubscription &,
    .blockHero--background & {
        color: fade(#fff, 80%);
        &:hover {
            color: #fff;
        }
    }
}
