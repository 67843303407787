@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('/static/fonts/montserrat-medium-webfont.woff2') format('woff2'), url('/static/fonts/montserrat-medium-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('/static/fonts/montserrat-semibold-webfont.woff2') format('woff2'), url('/static/fonts/montserrat-semibold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('/static/fonts/montserrat-bold-webfont.woff2') format('woff2'), url('/static/fonts/montserrat-bold-webfont.woff') format('woff');
}
html {
  font-size: 16px;
  height: 100%;
}
body {
  background-color: #f4f4f4;
  box-sizing: border-box;
  color: #171A19;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  min-height: 100%;
  padding: 75px 0 0;
}
@media (min-width: 860px) {
  body {
    padding-top: 105px;
  }
}
body.template--specProjects {
  background: #000;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.07%, #000000 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('/static/images/backgrounds/subscription.jpg');
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}
body.dark {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}
body.templateSubscription,
body.template-specProjectPage,
body.template-article,
body.template-searchresults,
body.template-homepage {
  padding: 65px 0 0;
}
@media (min-width: 860px) {
  body.templateSubscription,
  body.template-specProjectPage,
  body.template-article,
  body.template-searchresults,
  body.template-homepage {
    padding-top: 95px;
  }
}
@media (min-width: 860px) {
  body.templateSubscription,
  body.templateVideoItem {
    padding-top: 95px;
  }
}
body.open {
  overflow: hidden;
}
h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 30px;
  text-align: center;
}
@media (min-width: 768px) {
  h1 {
    font-size: 35px;
    line-height: 42px;
    margin: 0 0 40px;
  }
}
.template-magazine h1,
.template--newsItem h1 {
  text-align: left;
}
.subscriptionInfo h1 {
  z-index: 1;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 50px;
  padding: 0 15px;
}
h2 {
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 30px;
}
.blockText h2:not(:first-child) {
  margin: 50px 0 30px;
}
h3 {
  margin-top: 0;
}
h4 {
  margin-top: 0;
}
h5 {
  margin-bottom: 1rem;
  font-size: 1.00012rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  text-transform: uppercase;
}
a {
  color: #F05300;
  transition: all 0.2s;
  text-decoration: none;
}
.btn {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  vertical-align: middle;
  transition: 0.15s ease;
  min-width: 150px;
}
.btn:hover {
  background-color: #000;
  color: #fff;
}
body.dark .contactInfo .btn,
body.template--specProjects .contactInfo .btn {
  color: #fff;
  padding: 3px 0.75rem;
  border-color: #fff;
}
body.dark .contactInfo .btn:hover,
body.template--specProjects .contactInfo .btn:hover {
  background-color: #fff;
  color: #000;
}
body:not(.template--specProjects):not(.dark) .contactInfo .btn {
  color: #000;
  padding: 3px 0.75rem;
}
body:not(.template--specProjects):not(.dark) .contactInfo .btn:hover {
  background-color: #000;
  color: #fff;
}
.nav .btn {
  min-width: 12em;
  margin: 8px 0;
}
.magazineBody .btn {
  margin: 0 5px;
  min-width: 100px;
  padding: 8px 0.75rem;
}
.subscriptionInfo .btn {
  text-transform: uppercase;
}
.cookies .btn,
body.dark aside .btn,
.section--lektorii .btn,
.section--photos .btn,
.section--videos .btn,
.section--reportazhi .btn,
.section--chitanka .btn {
  color: #fff;
  border-color: #fff;
}
.cookies .btn:hover,
body.dark aside .btn:hover,
.section--lektorii .btn:hover,
.section--photos .btn:hover,
.section--videos .btn:hover,
.section--reportazhi .btn:hover,
.section--chitanka .btn:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.cookies .btn {
  min-width: 90px;
}
.btn--primary {
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
}
.btn--primary:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.cookies .btn--primary,
body.dark .contactInfo .btn--primary,
body.template--specProjects .contactInfo .btn--primary {
  background-color: #F05300;
  border-color: #F05300;
}
.cookies .btn--primary:hover,
body.dark .contactInfo .btn--primary:hover,
body.template--specProjects .contactInfo .btn--primary:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
body:not(.template--specProjects):not(.dark) .contactInfo .btn--primary {
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
}
body:not(.template--specProjects):not(.dark) .contactInfo .btn--primary:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.btnGroup {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.btnGroup li {
  margin-bottom: 10px;
}
.btnGroup li:not(:last-child) {
  margin-right: 10px;
}
p {
  margin: 0 0 25px;
}
figure {
  display: table;
  margin: 0;
  position: relative;
  text-align: center;
}
.blockSlider__item figure {
  margin: 0 auto;
}
.blockEmbed figure {
  width: 100%;
}
figcaption {
  border-bottom: 1px solid #e5e5e5;
  caption-side: bottom;
  display: table-caption;
  font-size: 13px;
  line-height: 20px;
  margin: 0 15px;
  max-width: 660px;
  padding: 10px 0;
  text-align: left;
}
.blockEmbed figcaption {
  margin: 0 auto;
  text-align: center;
}
figcaption span {
  color: #999999;
  display: block;
  font-family: Arial, Helvetica, Geneva, sans-serif;
  font-style: italic;
  font-weight: normal;
}
figcaption p {
  margin-bottom: 4px;
}
blockquote {
  box-sizing: border-box;
  margin: 60px auto 70px;
  max-width: 1000px;
  padding: 70px 15px 0;
  text-align: center;
  position: relative;
  font-size: 22px;
}
@media (min-width: 768px) {
  blockquote {
    padding-left: 100px;
    padding-right: 100px;
  }
}
blockquote::after {
  background-color: #f4f4f4;
  padding: 0 20px;
  color: #F05300;
  content: '\201D';
  display: inline-block;
  font-family: serif;
  font-size: 120px;
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 0;
  left: calc(50% - 45px);
  z-index: -1;
}
body.dark blockquote::after {
  background-color: #191919;
}
blockquote::before {
  border-top: 1px solid #f05300;
  content: '';
  left: calc(50% - 115px);
  opacity: 0.5;
  position: absolute;
  top: 22px;
  width: 230px;
  z-index: -2;
}
blockquote p {
  position: relative;
  z-index: 1;
  font-style: italic;
}
blockquote footer {
  position: relative;
  z-index: 1;
  font-style: normal;
  text-align: right;
  font-size: 16px;
}
iframe {
  margin-bottom: 30px;
}
main {
  flex: 1;
  margin-bottom: 60px;
}
.template-rubricsIndex main,
.templateVideoIndex main,
.templateSubscription main {
  margin-bottom: 0;
}
.template-homepage main {
  margin-bottom: 0;
}
.template-articlesIndex main {
  margin-bottom: 0;
}
main header {
  text-align: center;
  margin-bottom: 60px;
}
.container {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .container {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .container--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .container--newsItem {
    grid-gap: 60px;
  }
}
.hidden {
  display: none;
}
.richtext-image {
  display: block;
  height: auto;
  max-width: 100%;
}
.richtext-image.full-width {
  margin: 0 auto 30px;
}
.richtext-image.right {
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .richtext-image.right {
    float: right;
    margin: 5px -100px 30px 30px;
  }
}
.richtext-image.left {
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .richtext-image.left {
    float: left;
    margin: 5px 30px 30px -100px;
  }
}
.textLeft {
  text-align: left !important;
}
.arrowLeft {
  transform: rotate(180deg);
}
table {
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
}
table caption {
  font-weight: bold;
  padding: 0 0 15px;
}
table td,
table th {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.htLeft {
  text-align: left;
}
.htRight {
  text-align: right;
}
.htCenter {
  text-align: center;
}
.htJustify {
  text-align: justify;
}
.htTop {
  vertical-align: top;
}
.htMiddle {
  vertical-align: middle;
}
.htBottom {
  vertical-align: bottom;
}
.page__header {
  left: 0;
  padding: 14px 0;
  position: fixed;
  top: 0;
  transition: all 0.4s;
  width: 100%;
  z-index: 4;
}
body:not(.template--specProjects):not(.dark) .page__header {
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(2px);
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.1);
}
@media (min-width: 860px) {
  .page__header {
    padding: 16px 0 8px;
  }
}
.page__header.open {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.98);
  bottom: 0;
  top: 0 !important;
}
body.dark .page__header.open,
.template--specProjects .page__header.open {
  background-color: rgba(0, 0, 0, 0.98);
}
body.dark .page__header.visual,
.template--specProjects .page__header.visual {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.95);
}
.logo {
  display: block;
  width: auto;
  max-height: 32px;
}
@media (min-width: 860px) {
  .logo {
    max-height: 53px;
    margin-bottom: 12px;
  }
}
.navSection {
  display: none;
  width: 100%;
  padding: 35px 0;
}
@media (min-width: 860px) {
  .navSection {
    display: block;
    width: auto;
    padding: 0;
    max-width: calc(100% - 300px);
  }
}
.open .navSection {
  display: block;
}
.navbar {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .navbar {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .navbar--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .navbar--newsItem {
    grid-gap: 60px;
  }
}
.contactInfo {
  align-items: center;
  color: #DCDEDE;
  font-size: 12px;
  font-weight: 300;
  justify-content: flex-end;
  letter-spacing: 0.2px;
  line-height: 14px;
  list-style: none;
  margin: 0 0 6px;
  padding: 0;
  display: none;
}
@media (min-width: 860px) {
  .contactInfo {
    display: flex;
  }
}
@media (min-width: 860px) {
  .contactInfo li {
    margin-left: 18px;
  }
}
.contactInfo__search {
  padding-right: 18px;
  border-right: 1px solid #828282;
}
.nav {
  font-size: 0.875rem;
  font-weight: 600;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
@media (min-width: 860px) {
  .nav {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: right;
  }
}
.open .nav {
  text-align: center;
}
@media (min-width: 860px) {
  .nav__item {
    margin-left: 15px;
  }
}
@media (min-width: 860px) {
  .nav__item--btn {
    display: none;
  }
}
.nav__link {
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
}
body.dark .nav__link,
.template--specProjects .nav__link {
  color: rgba(255, 255, 255, 0.75);
}
.nav__link::before {
  content: '';
  position: absolute;
  width: calc(100% - 1rem);
  height: 0.125rem;
  left: 0.5rem;
  bottom: 0.35rem;
  transform: scaleX(0);
  transform-origin: left;
  will-change: transform;
  transition: transform 0.3s ease;
  background-color: #F05300;
  opacity: 0.85;
}
.nav__link.active,
.nav__link.ancestor,
.nav__link:hover {
  color: rgba(0, 0, 0, 0.7);
}
body.dark .nav__link.active,
body.dark .nav__link.ancestor,
body.dark .nav__link:hover,
.template--specProjects .nav__link.active,
.template--specProjects .nav__link.ancestor,
.template--specProjects .nav__link:hover {
  color: #fff;
}
.nav__link.active::before,
.nav__link.ancestor::before,
.nav__link:hover::before {
  transform: scaleX(1);
}
.nav__link svg {
  max-width: 10px;
  height: auto;
  margin-left: 6px;
  display: block;
  margin-bottom: 3px;
  opacity: 0.8;
}
.mainmenu__dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (min-width: 860px) {
  .mainmenu__dropdown {
    margin-left: 10px;
  }
}
.mainmenu__dropdown.open ul,
.mainmenu__dropdown:hover ul {
  display: block;
}
@media (min-width: 860px) {
  .mainmenu__dropdown.open ul,
  .mainmenu__dropdown:hover ul {
    position: absolute;
    right: 0;
    top: 100%;
  }
}
.mainmenu__dropdown ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}
@media (min-width: 860px) {
  .mainmenu__dropdown ul {
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: none;
    min-width: 140px;
  }
}
.mainmenu__dropdown ul li.active a {
  color: #F05300;
}
.mainmenu__dropdown ul a {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  padding: 0.5rem;
  white-space: nowrap;
}
@media (min-width: 860px) {
  .mainmenu__dropdown ul a {
    padding: 2px 15px;
    text-align: right;
  }
}
.mainmenu__dropdown ul a:hover {
  color: #F05300;
}
.mainmenu__dropdown__btn {
  padding: 15px 5px;
  background: transparent;
  border: 0;
  display: none;
}
@media (min-width: 860px) {
  .mainmenu__dropdown__btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.mainmenu__dropdown__btn.open,
.mainmenu__dropdown__btn:hover {
  cursor: pointer;
}
.mainmenu__dropdown__btn.open span,
.mainmenu__dropdown__btn:hover span {
  background-color: #F05300;
}
.mainmenu__dropdown__btn span {
  display: block;
  height: 6px;
  width: 6px;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 0 1px 0 2px;
  border-radius: 50%;
}
body.dark .mainmenu__dropdown__btn span,
.template--specProjects .mainmenu__dropdown__btn span {
  background-color: rgba(255, 255, 255, 0.7);
}
.nav__item {
  position: relative;
}
.nav__item:hover ul {
  display: block;
}
@media (min-width: 860px) {
  .nav__item:hover ul {
    position: absolute;
    left: 0;
  }
}
.nav__item ul {
  background-color: #fff;
  list-style: none;
  padding: 10px 0;
  margin: 0 0 10px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25);
}
@media (min-width: 860px) {
  .nav__item ul {
    display: none;
    min-width: 140px;
  }
}
.nav__item ul li.active a {
  color: #F05300;
}
.nav__item ul a {
  display: block;
  padding: 2px 15px;
  color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 860px) {
  .nav__item ul a {
    text-align: left;
  }
}
.nav__item ul a:hover {
  color: #F05300;
}
.navSectionMobile {
  margin: 0 -10px 0 0;
}
@media (min-width: 860px) {
  .navSectionMobile {
    display: none;
  }
}
.navbar__toggle {
  align-items: center;
  background: transparent;
  border: 0;
  color: #000;
  cursor: pointer;
  outline: none;
  padding: 10px;
  z-index: 2;
}
body.dark .navbar__toggle,
.template--specProjects .navbar__toggle {
  color: #fff;
}
.open .navbar__toggle--nav {
  color: #F05300;
}
.navbar__toggle img {
  display: block;
}
.header__socialLink {
  color: #828282;
}
.footer__social .header__socialLink {
  color: #828282;
}
.header__socialLink:hover {
  color: #F05300;
}
.footer__social .header__socialLink:hover {
  color: #fff;
}
.footer {
  color: rgba(255, 255, 255, 0.5);
  background-color: #171A19;
}
.footer h5 {
  margin-bottom: 18px;
}
.footer .btn {
  background-color: #fff;
  border-color: #fff;
  margin: 0 10px 10px 0;
}
.footer .btn:hover {
  background-color: #000;
}
.footer .btn--primary {
  background-color: #F05300;
  border-color: #F05300;
}
.footer .btn--primary:hover {
  border-color: #fff;
}
.footer__logo {
  margin-bottom: 1rem;
  display: block;
}
.footer__main {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
  display: grid;
  font-size: 0.875rem;
  font-weight: 500;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  margin: 60px auto 40px;
}
@media (min-width: 768px) {
  .footer__main {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .footer__main--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .footer__main--newsItem {
    grid-gap: 60px;
  }
}
@media (min-width: 860px) {
  .footer__main {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
  }
}
.footer__main__item--center {
  display: flex;
}
@media (min-width: 860px) {
  .footer__main__item--center {
    justify-content: center;
  }
}
.footer__meta {
  border-top: 1px solid #4F4F4F;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 8px 0 6px;
  line-height: 1.2;
}
.footer__meta p {
  margin: 0 30px 0 0;
  text-transform: uppercase;
  flex: 1;
}
.footer__meta a {
  color: #fff;
}
.footer__meta .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__meta .container > div {
  margin-right: 30px;
}
.footer__sitemap {
  color: #F05300;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 0 0 1rem;
}
.footer__sitemap a {
  padding: 0 0 0.5rem;
  color: #fff;
  display: block;
}
.footer__sitemap a:hover {
  opacity: 0.55;
}
.footer__contacts {
  list-style: none;
  margin: 0 0 40px;
  padding: 0;
  color: #fff;
}
.footer__contacts li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem !important;
}
.footer__contacts img {
  margin-right: 10px;
}
.footer__contacts a {
  display: block;
  color: #fff;
}
.footer__social {
  display: grid;
  display: inline-flex;
  grid-gap: 30px;
  grid-template-columns: auto auto auto;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer__social a {
  color: #fff;
}
.section__title {
  font-size: 1.61875rem;
  text-align: center;
  margin: 0 0 40px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .section__title {
    margin: 0 0 60px;
  }
}
.section__title a {
  color: #171A19;
  text-decoration: none;
}
.section__title a:hover {
  color: #F05300;
}
.section--reportazhi .section__title,
.section--chitanka .section__title,
.section--lektorii .section__title,
.section--videos .section__title,
.section--photos .section__title {
  color: #fff;
}
.section__footer {
  text-align: center;
}
.section {
  padding: 50px 0 60px;
}
@media (min-width: 768px) {
  .section {
    padding: 60px 0;
  }
}
.sectionLivehistory {
  position: relative;
  color: #fff;
  text-align: center;
  padding: 80px 20px;
}
.sectionLivehistory::after {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sectionLivehistory h2 {
  margin-bottom: 20px;
  font-weight: 400;
}
.sectionLivehistory video {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}
.sectionLivehistory img {
  margin: 0 auto 30px;
  display: block;
}
.sectionLivehistory p {
  margin-bottom: 30px;
}
.sectionLivehistory a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.sectionLivehistory .container {
  position: relative;
  z-index: 1;
}
.section--intro {
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;
}
.section--kolonki .cards {
  margin-bottom: 40px;
}
.section--statti {
  padding-top: 0;
}
.section--statti .cards {
  margin-bottom: 40px;
}
.section--bez-bromu {
  padding-top: 0;
}
.section--lektorii {
  background-image: url('/static/images/backgrounds/lektoriy.jpg');
  background-size: cover;
  background-position: 50% 50%;
}
.section--reportazhi {
  background-image: url('/static/images/bg_reportazh.jpg');
  background-size: cover;
  background-position: 50% 50%;
  padding: 60px 0;
}
.section--reportazhi .cards {
  margin-bottom: 40px;
}
.section--interviu .cards {
  margin-bottom: 40px;
}
.section--chitanka {
  background-image: url('/static/images/bg_reportazh.jpg');
  background-size: cover;
  background-position: 50% 50%;
  padding: 60px 0;
}
.section--chitanka .cards {
  margin-bottom: 40px;
}
.section--videos {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/static/images/backgrounds/video.jpg');
  background-size: cover;
  background-position: 50% 50%;
  color: #fff;
}
.section--photos {
  background-image: url('/static/images/bg_album.jpg');
  background-size: cover;
  background-position: 50% 50%;
  color: #fff;
}
.section--whoWeAre {
  padding: 70px 0 40px;
  text-align: center;
  max-width: 860px;
  margin: auto;
}
@media (min-width: 768px) {
  .section--whoWeAre {
    padding: 120px 0;
  }
}
.section--carousel {
  min-height: 100%;
}
@media (min-height: 660px) {
  .section--carousel {
    height: 100%;
  }
}
.section--services {
  padding: 60px 0;
  background-color: #f8f8f8;
}
@media (min-width: 768px) {
  .section--services {
    padding: 70px 0;
  }
}
.service__image {
  float: right;
  margin: 0 0 30px 30px;
}
.section--cases {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .section--cases {
    padding: 70px 0;
  }
}
.page__subtitle {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 18px;
}
.page__content {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .page__content {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .page__content--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .page__content--newsItem {
    grid-gap: 60px;
  }
}
@media (min-width: 768px) {
  .page__content {
    margin-bottom: 60px;
  }
}
.case__description {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .case__description {
    margin-bottom: 60px;
  }
}
.page__imageVisual {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .page__imageVisual {
    margin-bottom: 60px;
  }
}
.page__image {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}
.case__info {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
@media (min-width: 768px) {
  .case__info {
    grid-gap: 60px;
    grid-template-columns: 3fr 2fr 7fr;
    justify-content: space-between;
  }
}
.case__services {
  padding: 0 0 0 18px;
  margin: 0;
}
.section--videos {
  overflow: hidden;
}
.blockEmbed {
  margin: 0 0 30px;
}
.blockEmbed--middle {
  max-width: 1000px;
  margin: 0 auto 30px;
}
.blockEmbed--preview {
  margin: 0 auto 60px;
}
.blockEmbed iframe {
  display: block;
  margin: 0;
  min-height: 260px;
  width: 100%;
}
@media (min-width: 768px) {
  .blockEmbed iframe {
    height: 60vh;
  }
}
.blockSingleImage {
  margin: 0 0 30px;
}
.blockSingleImage--middle {
  max-width: 1000px;
  margin: 0 auto 30px;
}
.blockImages {
  margin: 0 0 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
.blockImages--middle {
  max-width: 1000px;
  margin: 0 auto 30px;
}
@media (min-width: 768px) {
  .blockImages--col2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .blockImages--col3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .blockImages--col4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.blockImages figure {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.blockImageVisual {
  position: relative;
}
.blockImage {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}
.blockShareMe {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  list-style: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .blockShareMe {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .blockShareMe--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .blockShareMe--newsItem {
    grid-gap: 60px;
  }
}
@media (min-width: 768px) {
  .blockHero--background .blockShareMe {
    text-align: right;
    justify-content: flex-end;
  }
}
.blockShareMe li {
  margin: 0 7px;
}
@media (min-width: 768px) {
  .blockHero--background .blockShareMe li {
    margin: 0 0 0 15px;
  }
}
.blockShareMe a {
  display: block;
  opacity: 0.65;
  outline: none;
}
.blockShareMe a:hover {
  opacity: 1;
}
.blockShareMe img {
  display: block;
}
body.dark .blockShareMe img,
.blockHero--background .blockShareMe img {
  filter: invert(100%);
}
.templateVideoItem .blockShareMe {
  justify-content: center;
  text-align: center;
}
.template--newsItem .blockShareMe {
  margin-bottom: 40px;
}
.viewGallery {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  opacity: 0.65;
}
.viewGallery:hover {
  opacity: 1;
}
@media (min-width: 768px) {
  .viewGallery {
    margin: 0 25px 0 0;
  }
}
.viewGallery img {
  display: block;
}
.blockDate {
  color: #000;
  font-weight: 600;
  margin-bottom: 8px;
}
.dark .blockDate {
  color: #fff;
}
.blockDate span {
  color: #8e8e8e;
  font-weight: 400;
  transition: all 0.15s ease;
}
.blockText {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .blockText {
    padding: 0 100px;
  }
}
.subscriptionInfo .blockText {
  max-width: 760px;
}
.subscriptionInfo .blockText a {
  color: #fff;
  text-decoration: underline;
}
.template--newsItem .blockText {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
  box-sizing: initial;
  padding: 0;
}
@media (min-width: 768px) {
  .template--newsItem .blockText {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .template--newsItem .blockText--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .template--newsItem .blockText--newsItem {
    grid-gap: 60px;
  }
}
@media (min-width: 768px) {
  .template--newsItem .blockText {
    padding: 0;
  }
}
.blockText iframe {
  width: 100%;
  display: block;
}
.blockText--stylish {
  border-radius: 4px;
  margin: 0 15px 30px;
  max-width: 800px;
  padding: 30px 30px 0;
}
.blockText--stylish::after {
  content: ' ';
  display: block;
  width: 100%;
  clear: both;
  height: 1px;
}
.template--newsItem .blockText--stylish {
  padding: 30px 30px 0;
  margin: 0 auto 30px;
}
@media (min-width: 768px) {
  .template--newsItem .blockText--stylish {
    padding: 30px 30px 0;
  }
}
@media (min-width: 768px) {
  .blockText--stylish {
    margin: 0 100px 30px;
  }
}
@media (min-width: 1000px) {
  .blockText--stylish {
    margin: 0 auto 30px;
  }
}
.blockTextImage {
  position: relative;
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .blockTextImage.left {
    float: left;
    margin: 0 30px 30px -100px;
  }
  .template--newsItem .blockTextImage.left {
    margin: 0 30px 30px -30px;
  }
}
@media (min-width: 768px) {
  .blockTextImage.right {
    float: right;
    margin: 0 -100px 30px 30px;
  }
  .template--newsItem .blockTextImage.right {
    margin: 0 0 30px 30px;
  }
}
@media (min-width: 1200px) {
  .template--newsItem .blockTextImage.right {
    margin: 0 -30px 30px 30px;
  }
}
.blockTextImage figcaption {
  max-width: 90%;
  margin: auto;
}
.article__preview__link {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  outline: none;
}
.tooltipBlock {
  background-image: url('/static/images/icons/info.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 17px;
  position: relative;
  vertical-align: text-bottom;
  width: 17px;
  z-index: 1;
}
.template--specProjects .tooltipBlock,
.dark .tooltipBlock {
  filter: invert(100%);
  color: #000;
}
.tooltipBlock__visual {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 30px 90px -20px rgba(0, 0, 0, 0.3), 0 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: initial;
  display: none;
  font-size: 14px;
  left: 100%;
  margin-left: 10px;
  padding: 8px 14px 10px 10px;
  position: absolute;
  top: -10px;
  transition: all ease 0.35s;
}
.template--specProjects .tooltipBlock__visual,
.dark .tooltipBlock__visual {
  box-shadow: 0 30px 90px -20px #fff, 0 0 1px 1px #fff;
}
.tooltipBlock:hover .tooltipBlock__visual {
  display: block;
  width: 200px;
}
.tooltipBlock__visual::after {
  border-color: transparent #fff transparent transparent;
  border-style: solid;
  border-width: 8px;
  content: "";
  position: absolute;
  right: 100%;
  top: 10px;
}
.left .tooltipBlock__visual {
  left: auto;
  margin-right: 10px;
  right: 100%;
}
.left .tooltipBlock__visual::after {
  border-color: transparent transparent transparent #fff;
  left: 100%;
  right: auto;
}
.blockTable {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .blockTable {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .blockTable--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .blockTable--newsItem {
    grid-gap: 60px;
  }
}
.blockHero {
  box-sizing: border-box;
  margin: 0 0 40px;
  padding: 10px 0 40px;
  position: relative;
}
@media (min-width: 768px) {
  .blockHero {
    margin: 0 0 60px;
  }
}
.blockHero--background {
  color: #fff;
}
@media (min-width: 768px) {
  .blockHero--background {
    margin: 0 0 80px;
  }
}
.template-searchresults .blockHero--background {
  background-image: url("/static/images/backgrounds/lektoriy.jpg");
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .blockHero--background {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 600px;
  }
  .template-searchresults .blockHero--background {
    min-height: 300px;
  }
}
.blockHero--background::after {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.template-searchresults .blockHero--background::after {
  display: none;
}
.template-rubricItem .blockHero {
  padding: 10px 0 0;
}
.template-searchresults .blockHero {
  margin: 0 0 15px;
  padding: 10px 0 40px;
}
@media (min-width: 768px) {
  .template-searchresults .blockHero {
    margin-bottom: -60px;
    padding: 10px 0 120px;
  }
}
.blockHero h1 {
  text-align: center;
}
.blockHero p {
  text-align: center;
  margin-bottom: 40px;
}
.blockHero .container {
  z-index: 1;
  position: relative;
}
.templateVideoItem .blockHero {
  margin: 0;
}
.blockHero__image {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.blockHeroNavigation {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 20px;
  max-width: 600px;
}
.blockHeroNavigation p {
  margin: 0;
}
.blockDescription {
  margin: 0 auto 60px;
  max-width: 1140px;
}
@media (min-width: 768px) {
  body:not(.template--newsItem):not(.template--gallery) .blockDescription {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: auto 360px;
    grid-gap: 40px;
    margin: 0 auto 90px;
  }
  body:not(.template--newsItem):not(.template--gallery) .blockDescription.alignCenter {
    grid-template-columns: auto;
    justify-content: center;
    text-align: center;
  }
}
.template-specProjectPage .blockDescription,
.templateVideoItem .blockDescription,
.template-article .blockDescription {
  padding: 0 15px;
}
.template-magazine .blockDescription {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 40px;
}
.templateMagazineCollection .blockDescription {
  margin-bottom: 60px;
  max-width: 760px;
}
.template--newsItem .blockDescription {
  margin-bottom: 40px;
}
.template--gallery .blockDescription {
  text-align: center;
}
.blockDescription__visual {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .blockDescription__visual {
    margin-bottom: 0;
  }
}
.templateVideoIndex .blockDescription__visual,
.templateVideoItem .blockDescription__visual {
  margin: auto;
  text-align: center;
}
.template--newsItem .blockDescription__visual {
  max-width: 100%;
}
.blockDescription__visual p:last-child {
  margin-bottom: 0;
}
.blockProjects {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .blockProjects {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .blockProjects--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .blockProjects--newsItem {
    grid-gap: 60px;
  }
}
.blockAuthors {
  margin: 0 auto;
  width: 100%;
}
.blockAuthors__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  text-align: left;
  position: relative;
  padding: 10px 15px 10px 5px;
  border-radius: 10px;
}
.blockAuthors__item:hover {
  background-color: #eee;
}
.dark .blockAuthors__item:hover {
  background-color: #000;
}
.blockAuthors__item h4 {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
@media (min-width: 768px) {
  .blockAuthors__item h4 {
    margin: 0 0 10px;
  }
}
.dark .blockAuthors__item h4 {
  color: #fff;
}
.blockAuthors__item__img {
  display: block;
  margin: 0 15px 0 0;
  border-radius: 50%;
  border: 3px solid #fff;
  width: 60px;
  height: 60px;
}
@media (min-width: 768px) {
  .blockAuthors__item__img {
    margin-right: 25px;
    width: 110px;
    height: 110px;
  }
}
.blockAuthors__item:hover .blockAuthors__item__img {
  border-color: #F05300;
}
.authorHeader .blockAuthors__item__img {
  margin: 0 auto 15px;
  width: 150px;
  height: 150px;
}
.blockAuthors__item__job {
  margin: 0;
  color: #000;
  line-height: 22px;
}
.dark .blockAuthors__item__job {
  color: #fff;
}
.blockTags {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 60px auto;
  max-width: 1000px;
  padding: 0 15px;
  flex-wrap: wrap;
  clear: both;
}
.blockTag__link {
  background-color: #9e9e9e;
  border-radius: 3px 0 0 3px;
  color: #fff;
  display: block;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  margin: 0 10px 10px 0;
  padding: 0 20px 0 23px;
  position: relative;
  text-transform: uppercase;
}
.blockTag__link::before {
  background-color: #f4f4f4;
  border-radius: 50%;
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 6px;
}
.blockTag__link::after {
  background-color: #f4f4f4;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #9e9e9e;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
}
body.dark .blockTag__link::after {
  background-color: rgba(0, 0, 0, 0.9);
}
.blockTag__link:hover {
  background-color: #F05300;
}
.blockTag__link:hover::after {
  border-left: 10px solid #F05300;
}
.sliderVisual {
  box-sizing: border-box;
  margin: 0 auto 30px;
  max-width: 1000px;
  padding: 0;
  position: relative;
}
.blockSlider__item {
  position: relative;
}
.listNews {
  list-style: none;
  margin: 0 auto 30px;
  max-width: 920px;
  padding: 0;
}
@media (min-width: 768px) {
  .listNews {
    margin-bottom: 40px;
  }
}
.section--news .listNews {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .section--news .listNews {
    grid-gap: 10px 60px;
    grid-template-columns: 1fr 1fr;
  }
}
.listItem {
  padding: 0;
  margin: 0 0 30px;
  position: relative;
}
.template--newsCollection .listItem {
  background-image: url("/static/images/icons/bullet.svg");
  background-position: 0 19px;
  background-repeat: no-repeat;
  padding: 15px 15px 15px 30px;
  margin: 0;
}
.listItem__date {
  font-size: 14px;
  line-height: 17px;
  transition: all 0.15s ease;
}
.listItem:hover .listItem__date {
  color: #F05300;
}
.listItem:hover .listItem__date span {
  color: #F05300;
}
.listItem__title {
  margin: 0 0 6px;
  transition: all 0.15s ease;
  font-weight: 600;
}
.listItem:hover .listItem__title {
  color: #F05300;
}
.listItem__link {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.magazineBody {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .magazineBody {
    grid-template-columns: 2fr 3fr;
    margin-bottom: 60px;
  }
}
.magazineBody .blockText {
  padding: 0;
}
.magazineBody__preview {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.magazineBody__preview__cover {
  background-color: #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05) !important;
  box-sizing: border-box;
  display: block;
  height: auto;
  margin-bottom: 5px;
  max-width: 100%;
  padding: 15px;
}
.magazineBody__preview__gallery {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
  max-width: 466px;
}
.magazineBody__preview__link {
  display: block;
}
.magazineBody__preview__image {
  display: block;
  height: auto;
  max-width: 100%;
}
.magazineBody__info {
  display: none;
}
@media (min-width: 768px) {
  .magazineBody__info {
    display: block;
  }
}
@media (min-width: 768px) {
  .magazineBody__infoMobile {
    display: none;
  }
}
.magazineBodyOrder {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.magazineBodyOrder__price {
  font-size: 1.61875rem;
  font-weight: bold;
}
.magazineBodyStatus {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  display: inline-flex;
  font-size: 12px;
}
.magazineBodyStatus::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
  background-color: #ccc;
}
.magazineBodyStatus.selling {
  background-color: #34bd03;
  color: #fff;
}
.magazineBodyStatus.selling::before {
  background-color: #fff;
}
.magazineBodyStatus.soldout {
  background-color: #aaa;
  color: #fff;
}
.magazineBodyStatus.soldout::before {
  background-color: #fff;
}
.magazineBodyStatus.expectation {
  background-color: #f9c807;
  color: #fff;
}
.magazineBodyStatus.expectation::before {
  background-color: #fff;
}
.carouselMagazine .magazineBodyStatus {
  position: absolute;
  top: 15px;
  left: 0;
}
.cardsMagazine .magazineBodyStatus {
  position: absolute;
  top: 15px;
  left: 0;
}
.subscriptionInfo {
  background-color: #000;
  background-image: url('/static/images/backgrounds/subscription.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #fff;
  position: relative;
  padding: 10px 0 60px;
}
.subscriptionInfo::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.07%, #000000 90.4%), linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
}
.subscriptionInfo::after {
  content: "";
  display: table;
  clear: both;
  width: 100%;
}
.subscriptionInfo .card {
  color: #000;
}
.subscriptionInfo .blockText,
.subscriptionInfo .sectionCards {
  z-index: 1;
  position: relative;
}
.subscriptionInfo h2 {
  text-align: center;
}
.subscriptionInfo .sectionCards {
  max-width: 760px;
  padding: 0 15px;
  margin: 0 auto 50px;
}
@media (min-width: 768px) {
  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }
}
.searchFormWrapper {
  margin: 0 2px 0 0;
}
@media (min-width: 768px) {
  .searchFormWrapper {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-wrap: wrap;
  }
}
.searchFormInput {
  border: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 1;
  margin: 0 0 2px;
  outline: 0;
  padding: 7px 15px 8px;
  width: 100%;
}
@media (min-width: 768px) {
  .searchFormInput {
    margin: 0 2px 0 0;
    min-width: 350px;
    width: auto;
  }
}
.searchFormButton {
  align-items: center;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  display: flex;
  margin: auto;
  padding: 6px 12px 8px;
  transition: 0.1s ease;
}
.searchFormButton:hover {
  background-color: #F05300;
  color: #fff;
}
.searchFormButton span {
  margin-left: 7px;
}
@media (min-width: 768px) {
  .searchFormButton span {
    display: none;
  }
}
.searchFormButton svg {
  display: block;
  height: auto;
  max-width: 20px;
}
.searchResultsCount {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .searchResultsCount {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0;
    padding: 0 30px;
  }
}
.searchResultsCount strong {
  font-size: 16px;
}
@media (min-width: 768px) {
  .searchResultsCount strong {
    color: #fff;
  }
}
.sectionReport {
  overflow: hidden;
  padding: 60px 0;
  position: relative;
}
@media (min-width: 1025px) {
  .sectionReport {
    padding: 90px 0;
  }
}
@media (min-width: 1025px) {
  .sectionReport .container {
    align-items: flex-start;
    display: grid;
    grid-gap: 60px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
}
.sectionReport__background {
  background-image: url("/static/images/bg_reportazh.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(45%) blur(10px);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.sectionReport__brightness {
  background-color: #555;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}
.sectionReport__preview {
  color: #fff;
  display: none;
  font-weight: normal;
}
@media (min-width: 1025px) {
  .sectionReport__preview {
    display: block;
  }
}
.sectionReport__preview h2 {
  font-size: 35px;
  line-height: 47px;
  text-transform: uppercase;
}
.sectionReport__preview a {
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
  font-size: 0.875rem;
  padding: 10px 20px;
  text-transform: uppercase;
}
.sectionReport__preview__tags {
  background-color: #efac00;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  margin: 0 0 30px;
  padding: 2px 10px;
  text-transform: uppercase;
  z-index: 1;
}
.sectionReport__content .section__title {
  color: #fff;
}
@media (min-width: 1025px) {
  .sectionReport__content .section__title {
    display: none;
  }
}
.sectionReport__content .btn {
  border-color: #fff;
  color: #fff;
  display: block;
  margin: auto;
  text-transform: uppercase;
  width: 150px;
}
.sectionReport__content .btn:hover {
  background-color: #fff;
  color: #000;
}
.sectionMicrorubric:nth-child(2n) {
  background-color: #eee;
}
.cookies {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1055;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(2px);
}
.cookies__visual {
  background-color: #000;
  color: #fff;
  max-width: 500px;
  margin: auto;
  max-height: 80%;
  position: relative;
  padding: 15px;
}
@media (min-width: 768px) {
  .cookies__visual {
    display: flex;
    padding: 30px;
  }
}
.cookies__image {
  max-width: 100px;
  height: auto;
  float: left;
  margin: 0 15px 15px 0;
  display: block;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.19);
}
@media (min-width: 768px) {
  .cookies__image {
    max-width: 260px;
    float: none;
    margin: 0 30px 0 -130px;
  }
}
.btnCookieClose {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 28px;
  cursor: pointer;
  border: 0;
  background-color: #000;
}
@media (min-width: 768px) {
  .btnCookieClose {
    top: 15px;
    right: 15px;
  }
}
@media (min-width: 768px) {
  .cookie__info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.cookie__info .magazineBodyStatus {
  background-color: transparent;
  margin-bottom: 20px;
  padding: 0;
}
.cookie__info .magazineBodyStatus.selling::before {
  background-color: #34bd03;
}
.cookie__info .magazineBodyStatus.expectation::before {
  background-color: #f9c807;
}
.cookie__header {
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 15px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .cookie__header {
    font-size: 30px;
    margin: 0;
  }
}
.cookie__header span {
  display: block;
  font-weight: normal;
}
.cookie__body {
  clear: both;
}
.cookie__title {
  margin-bottom: 0;
  line-height: 1;
}
.cookie__subtitle {
  margin-bottom: 15px;
}
.cookie__magazinePrice {
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .cookie__magazinePrice {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.authorHeader {
  text-align: center;
  margin: 0 0 60px;
}
.authorHeader h1 {
  margin: 0 0 20px;
}
.l {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}
@media (min-width: 680px) {
  .l2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 680px) {
  .l21 {
    grid-template-columns: 2fr 3fr;
  }
}
@media (min-width: 680px) {
  .l21 > div:nth-child(3n+1) {
    grid-column: span 2;
  }
}
@media (min-width: 560px) {
  .l3 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .l3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 560px) {
  .l4 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .l4 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1025px) {
  .l4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 560px) {
  .l41 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .l41 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .l41 > div:nth-child(3n+1) {
    grid-column: span 2;
  }
}
@media (min-width: 1025px) {
  .l41 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .l41 > div:nth-child(3n+1) {
    grid-column: 1;
  }
  .l41 > div:nth-child(4n+1) {
    grid-column: span 2;
  }
  .l41 > div:nth-child(12n-1) {
    grid-column: span 2;
  }
}
@media (min-width: 365px) {
  .l42 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .l42 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1025px) {
  .l42 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .select2-container {
    margin: 0 0 2px;
    width: 100% !important;
  }
}
.select2-container .selection {
  display: block;
  height: 100%;
}
.select2-container .select2-selection--single {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 100%;
  outline: 0;
  text-transform: uppercase;
  user-select: none;
}
.select2-container .select2-selection--single:hover {
  background-color: #eee;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding: 8px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 100%;
  user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container .select2-search--inline .select2-search__field {
  border: none;
  box-sizing: border-box;
  font-size: 100%;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: #fff;
  box-sizing: border-box;
  display: block;
  left: -100000px;
  position: absolute;
  width: 100%;
  z-index: 1051;
}
.select2-results {
  display: block;
  font-size: 14px;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results__option {
  padding: 10px 15px 9px;
  line-height: 1.1;
  text-transform: uppercase;
  user-select: none;
}
.select2-results__option:hover {
  background-color: #eee;
  cursor: pointer;
}
.select2-results__option--highlighted {
  background-color: #eee;
}
.select2-results__option--selectable {
  cursor: pointer;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  box-sizing: border-box;
  padding: 4px 10px;
  width: 100%;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select2-close-mask {
  background-color: #fff;
  border: 0;
  display: block;
  height: auto;
  left: 0;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: auto;
  z-index: 99;
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000;
  line-height: 1;
  font-size: 18px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--group {
  padding: 0;
}
.select2-container--default .select2-results__option--disabled {
  color: #999;
}
.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: 0;
  border-left: 1px solid #aaa;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  background: linear-gradient(to bottom, #eeeeee 50%, white 100%);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #aaa;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option--group {
  padding: 0;
}
.select2-container--classic .select2-results__option--disabled {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}
.tns-slider {
  transition: all 0s;
}
.tns-slider::after,
.tns-slider::before {
  content: '';
  width: 100%;
  clear: both;
  display: table;
}
.tns-slider > .tns-item {
  box-sizing: border-box;
}
.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}
.tns-no-calc {
  left: 0;
  position: relative;
}
.tns-gallery {
  left: 0;
  min-height: 1px;
  position: relative;
}
.tns-gallery > .tns-item {
  left: -100%;
  position: absolute;
  transition: transform 0, opacity 0;
}
.tns-gallery > .tns-slide-active {
  left: auto !important;
  position: relative;
}
.tns-gallery > .tns-moving {
  transition: all 0.25s;
}
.tns-autowidth {
  display: inline-block;
}
.tns-lazy-img {
  opacity: 0.6;
  transition: opacity 0.6s;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}
.tns-ah {
  transition: height 0;
}
.tns-ovh {
  overflow: hidden;
}
.tns-visually-hidden {
  left: -10000em;
  position: absolute;
}
.tns-transparent {
  opacity: 0;
  visibility: hidden;
}
.tns-fadeIn {
  opacity: 1;
  z-index: 0;
}
.tns-normal,
.tns-fadeOut {
  opacity: 0;
  z-index: -1;
}
.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > li {
  display: inline-block;
}
.tns-vpfix > div {
  display: inline-block;
}
.tns-t-subp2 {
  height: 10px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 310px;
}
.tns-t-ct {
  position: absolute;
  right: 0;
  width: calc(100% * 70 / 3);
}
.tns-t-ct::after {
  clear: both;
  content: '';
  display: table;
}
.tns-t-ct > div {
  float: left;
  height: 10px;
  width: calc(100% / 70);
}
.tns-nav {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.tns-nav button {
  background-color: #e0e0e0;
  border: 0;
  cursor: pointer;
  height: 4px;
  margin: 0 2px;
  overflow: hidden;
  width: 36px;
}
.tns-nav button.tns-nav-active {
  background-color: #F05300;
}
.carouselVisual {
  position: relative;
}
.templateSubscription .carouselVisual {
  padding: 60px 0;
}
.carouselMagazine {
  margin: 0 0 40px;
}
.carouselVideos {
  margin: 0 0 10px;
}
.carouselPhotos {
  margin: 0 0 15px;
}
.carouselHero {
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-wrap: wrap;
}
.carousel__item {
  position: relative;
}
.carouselMagazine .carousel__item {
  text-align: center;
}
.carousel__itemVisual {
  position: relative;
}
@media (min-width: 768px) {
  .carouselVideos .carousel__itemVisual {
    max-width: 800px;
    transition: all 0.15s ease;
    filter: blur(0.08rem) brightness(75%) saturate(25%);
  }
  .carouselVideos .carousel__itemVisual:hover {
    filter: none;
  }
}
@media (min-width: 768px) {
  .carouselPhotos .carousel__itemVisual {
    max-width: 440px;
    transition: all 0.15s ease;
    filter: blur(0.08rem) brightness(75%) saturate(25%);
  }
  .carouselPhotos .carousel__itemVisual:hover {
    filter: none;
  }
}
@media (min-width: 768px) {
  .carouselPhotos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active .carousel__itemVisual,
  .carouselVideos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active .carousel__itemVisual {
    filter: none;
  }
}
@media (min-width: 1000px) {
  .carouselPhotos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active .carousel__itemVisual,
  .carouselPhotos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active .carousel__itemVisual {
    filter: none;
  }
}
@media (min-width: 1200px) {
  .carouselVideos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active .carousel__itemVisual,
  .carouselVideos .carousel__item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active .carousel__itemVisual {
    filter: none;
  }
}
.carouselHero .carousel__itemVisual {
  overflow: hidden;
  height: 100%;
  position: relative;
}
@media (min-width: 960) {
  .carouselHero .carousel__itemVisual {
    min-height: 390px;
  }
}
@media (min-width: 1300px) {
  .carouselHero .carousel__itemVisual {
    height: 560px;
  }
}
@media (min-width: 960px) {
  .carouselHero .carousel__itemVisual:not(.slideVisual) {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}
.carouselHero .carousel__itemVisual:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.carouselHero .carousel__itemVisual:hover .btn {
  background-color: rgba(240, 83, 0, 0.75);
}
.carouselMagazine .carousel__item__text {
  margin: 0 10px 15px;
}
.carousel__item__text--price {
  font-size: 18px;
  font-weight: 600;
}
.carouselHero .carousel__item__text {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 0 auto;
}
.carouselMagazine .carousel__item__img__wrap {
  margin: 0 0 15px;
}
@media (min-width: 960px) {
  .carouselHero .carousel__itemVisual:not(.slideVisual) .carousel__item__img__wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
}
.carousel__item__image {
  display: block;
  height: auto;
  width: 100%;
}
@media (min-width: 960px) {
  .carouselHero .carousel__item__image {
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}
@media (min-width: 960px) {
  .carouselHero .slideVisual .carousel__item__image {
    bottom: 0;
    display: block;
    filter: brightness(45%);
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s ease-out;
    width: 100%;
  }
}
.carouselHero .carousel__item__body {
  padding: 15px 15px 35px;
}
@media (min-width: 960px) {
  .carouselHero .carousel__item__body {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px 30px 62px;
  }
}
@media (min-width: 1040px) {
  .carouselHero .carousel__item__body {
    padding: 62px 120px 62px 62px;
  }
}
@media (min-width: 960px) {
  .carouselHero .slideVisual .carousel__item__body {
    box-sizing: border-box;
    color: #fff;
    height: 100%;
    margin: auto;
    max-width: 1264px;
    position: relative;
    text-align: center;
    z-index: 2;
  }
  .carouselHero .slideVisual .carousel__item__body .btn {
    margin: auto;
  }
}
@media (min-width: 1040px) {
  .carouselHero .slideVisual .carousel__item__body {
    padding: 62px;
  }
}
.carouselHero .carousel__item__type {
  background-image: url("/static/images/icons/bullet.svg");
  background-position: 0 4px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  padding: 0 15px 0 25px;
}
.carouselHero .carousel__item__title {
  font-size: 28px;
  text-transform: none;
}
@media (min-width: 768px) {
  .carouselHero .carousel__item__title {
    font-size: 42px;
  }
}
.carouselVideos .carousel__item__title {
  text-align: center;
  padding: 20px 15px;
}
@media (min-width: 768px) {
  .carouselVideos .carousel__item__title {
    padding: 20px 30px;
  }
}
.carouselPhotos .carousel__item__title {
  text-align: center;
  padding: 20px 15px 5px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .carouselPhotos .carousel__item__title {
    padding: 20px 30px 5px;
  }
}
.carousel__item__count {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
  text-align: center;
}
.carousel__item__link {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.carouselMagazine .carousel__item__link {
  right: 30px;
}
.carouselMagazine .carousel__item__link:hover {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.8);
}
.carousel__item__linkVisual {
  display: none;
}
.carousel__item__link:hover .carousel__item__linkVisual {
  background-color: #F05300;
  color: #fff;
  display: block;
  font-size: 18px;
  line-height: 1;
  padding: 10px 15px;
}
.slider-prev {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  left: 0;
  outline: none;
  padding: 15px;
  position: absolute;
  top: 30%;
  z-index: 1;
}
@media (min-width: 960px) {
  .slider-prev {
    padding: 15px 30px;
  }
}
.section--intro .slider-prev {
  top: 0;
  left: auto;
  right: 50px;
}
@media (min-width: 960px) {
  .section--intro .slider-prev {
    right: auto;
    top: calc(50% - 32px);
  }
}
@media (min-width: 1340px) {
  .section--magazines .slider-prev,
  .templateSubscription .slider-prev {
    background-color: transparent;
    left: auto;
    right: 100%;
  }
}
.sliderVisual .slider-prev {
  top: 35%;
}
@media (min-width: 768px) {
  .section--photos .slider-prev,
  .section--videos .slider-prev {
    left: 30px;
  }
}
.slider-prev img {
  transform: rotate(180deg);
}
.slider-next {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 30%;
  z-index: 1;
  outline: none;
}
@media (min-width: 960px) {
  .slider-next {
    padding: 15px 30px;
  }
}
.section--intro .slider-next {
  top: 0;
}
@media (min-width: 960px) {
  .section--intro .slider-next {
    top: calc(50% - 32px);
  }
}
.sliderVisual .slider-next {
  top: 35%;
}
@media (min-width: 1340px) {
  .section--magazines .slider-next,
  .templateSubscription .slider-next {
    background-color: transparent;
    left: 100%;
    right: auto;
  }
}
@media (min-width: 768px) {
  .section--photos .slider-next,
  .section--videos .slider-next {
    right: 30px;
  }
}
.loader {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}
.loaderVisual {
  animation: load 1.1s infinite linear;
  border-bottom: 1.1em solid rgba(218, 211, 211, 0.2);
  border-left: 1.1em solid rgba(218, 211, 211, 0.2);
  border-right: 1.1em solid rgba(218, 211, 211, 0.2);
  border-top: 1.1em solid rgba(39, 33, 33, 0.2);
  font-size: 5px;
  left: calc(50% - 5em);
  position: absolute;
  top: calc(50% - 5em);
}
.loaderVisual,
.loaderVisual:after {
  border-radius: 50%;
  height: 10em;
  width: 10em;
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pagination {
  margin: 60px 0 0;
  text-align: center;
}
.pagination__link {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  vertical-align: middle;
  transition: 0.15s ease;
  min-width: 150px;
}
.pagination__link:hover {
  background-color: #000;
  color: #fff;
}
body.dark .contactInfo .pagination__link,
body.template--specProjects .contactInfo .pagination__link {
  color: #fff;
  padding: 3px 0.75rem;
  border-color: #fff;
}
body.dark .contactInfo .pagination__link:hover,
body.template--specProjects .contactInfo .pagination__link:hover {
  background-color: #fff;
  color: #000;
}
body:not(.template--specProjects):not(.dark) .contactInfo .pagination__link {
  color: #000;
  padding: 3px 0.75rem;
}
body:not(.template--specProjects):not(.dark) .contactInfo .pagination__link:hover {
  background-color: #000;
  color: #fff;
}
.nav .pagination__link {
  min-width: 12em;
  margin: 8px 0;
}
.magazineBody .pagination__link {
  margin: 0 5px;
  min-width: 100px;
  padding: 8px 0.75rem;
}
.subscriptionInfo .pagination__link {
  text-transform: uppercase;
}
.cookies .pagination__link,
body.dark aside .pagination__link,
.section--lektorii .pagination__link,
.section--photos .pagination__link,
.section--videos .pagination__link,
.section--reportazhi .pagination__link,
.section--chitanka .pagination__link {
  color: #fff;
  border-color: #fff;
}
.cookies .pagination__link:hover,
body.dark aside .pagination__link:hover,
.section--lektorii .pagination__link:hover,
.section--photos .pagination__link:hover,
.section--videos .pagination__link:hover,
.section--reportazhi .pagination__link:hover,
.section--chitanka .pagination__link:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.cookies .pagination__link {
  min-width: 90px;
}
.cardsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}
@media (min-width: 960px) {
  .cardsWrapper {
    grid-gap: 50px;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 50px;
  }
}
.cardsGroup {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
@media (min-width: 680px) {
  .cardsGroup {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 960px) {
  .cardsGroup {
    grid-template-columns: 1fr;
  }
}
.cards--video {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
@media (min-width: 768px) {
  .cards--video {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.cards--inequality {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cards--equability {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.subscriptionInfo .cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
@media (min-width: 768px) {
  .subscriptionInfo .cards {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 830px) {
  .subscriptionInfo .cards {
    grid-gap: 30px;
  }
}
.template-searchresults .cards {
  background-color: #f4f4f4;
  position: relative;
}
@media (min-width: 768px) {
  .template-searchresults .cards {
    padding: 30px 30px 0;
  }
}
.sectionReport .cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-bottom: 30px;
}
@media (min-width: 680px) {
  .sectionReport .cards {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}
.cardsProjectItems {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
@media (min-width: 768px) {
  .cardsProjectItems {
    grid-gap: 30px;
  }
}
@media (min-width: 768px) {
  .cardsProjectItems--col2 {
    grid-template-columns: 1fr 1fr;
  }
}
.cardsMagazine {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
@media (min-width: 480px) {
  .cardsMagazine {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .cardsMagazine {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .cardsMagazine {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
  }
}
.section--articles .cardsInterview {
  margin-bottom: 50px;
}
.cardsProject {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin: auto;
}
.cardsVideo {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
@media (min-width: 480px) {
  .cardsVideo {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .cardsVideo {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .cardsVideo {
    grid-gap: 30px;
  }
}
.templateVideoIndex .cardsVideo {
  margin-bottom: 50px;
}
.cardsAlboms {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
@media (min-width: 480px) {
  .cardsAlboms {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .cardsAlboms {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .cardsAlboms {
    grid-gap: 30px;
  }
}
.cardsMicrorubric {
  margin-bottom: 60px;
  grid-gap: 30px 0;
}
@media (min-width: 768px) {
  .cardsMicrorubric {
    grid-gap: 60px 30px;
  }
}
.card {
  position: relative;
  overflow: hidden;
}
.dark .card {
  color: #171A19;
}
.card--video {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}
.subscriptionInfo .card {
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}
.cardsMagazine .card {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  align-items: center;
}
.cardsVideo .card {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  align-items: center;
}
.cardsAlboms .card {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  align-items: center;
}
.cardsInequality .card {
  align-items: flex-end;
  background-color: #777;
  display: flex;
  min-height: 262px;
  width: 100%;
  transition: 0.2s ease;
}
.cardsInequality .card:hover {
  background-color: #222;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.cardsProjectItems .card {
  background-color: #777;
  display: flex;
  min-height: 262px;
  flex-direction: column;
  padding: 15px;
}
@media (min-width: 768px) {
  .cardsProjectItems .card {
    padding: 15px 30px 30px;
  }
}
.cardsProjectItems .card:hover {
  background-color: #222;
}
.cardsProjectItems .card:hover .card__image {
  filter: brightness(20%);
}
.cardsType3 .card {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  padding: 17px;
  transition: 0.15s ease-out;
}
.cardsType3 .card:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.cardsInterview .card {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  padding: 17px;
  transition: 0.15s ease-out;
  display: flex;
  flex-direction: column;
}
.cardsInterview .card:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.cardsType3 .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
}
.sectionReport .card,
.cardsEquality .card {
  align-items: flex-end;
  background-color: #777;
  display: flex;
  min-height: 262px;
}
.sectionReport .card:hover,
.cardsEquality .card:hover {
  background-color: #222;
}
.cardsProject .card {
  align-items: center;
  background-color: #777;
  display: flex;
  justify-content: center;
  min-height: 400px;
}
.cardsMicrorubric .card {
  padding: 0 15px;
  text-align: center;
}
.card__imageVisual {
  overflow: hidden;
}
.cardsMagazine .card__imageVisual {
  margin: 0 0 15px;
}
.cardsType3 .card__imageVisual {
  flex: 1;
}
.cardsInterview .card__imageVisual {
  flex: 1;
}
.cardsMicrorubric .card__imageVisual {
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 140px;
  justify-content: center;
  margin: 0 auto 15px;
  overflow: hidden;
  position: relative;
  width: 140px;
}
.card__image {
  display: block;
  transition: 0.3s ease;
}
.card:hover .card__image {
  transform: scale(1.05);
}
.cardsAlboms .card__image {
  max-width: 100%;
  height: auto;
}
.cardsVideo .card__image {
  max-width: 100%;
  height: auto;
}
.cardsInterview .card__image,
.cardsMagazine .card__image {
  max-width: 100%;
  height: auto;
}
.sectionReport .card__image,
.cardsEquality .card__image,
.cardsInequality .card__image {
  display: block;
  filter: brightness(45%);
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease-out;
  width: 100%;
}
.cardsType3 .card__image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  margin: 30px auto 20px;
  border: 4px solid #006D6D;
  display: block;
  object-fit: cover;
  object-position: 50% 50%;
}
.cardsType3 .card:hover .card__image {
  transform: scale(1);
  border-color: #F05300;
}
.sectionReport .card.active .card__image,
.sectionReport .card:hover .card__image,
.cardsProject .card:hover .card__image,
.cardsEquality .card:hover .card__image,
.cardsInequality .card:hover .card__image {
  filter: brightness(35%);
}
.cardsInterview .card__image {
  margin-bottom: 17px;
}
.cardsProjectItems .card__image {
  display: block;
  filter: brightness(45%);
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease-out;
  width: 100%;
}
.cardsProjectItems .card__image:hover {
  filter: brightness(20%);
}
.cardsProject .card__image {
  display: block;
  filter: brightness(45%);
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease-out;
  width: 100%;
}
.cardsProject .card__image:hover {
  filter: brightness(20%);
}
.template-rubricsCollection .cardsMicrorubric .card__image,
.template-rubricsIndex .cardsMicrorubric .card__image {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.15s linear;
  width: 100%;
}
.template-homepage .cardsMicrorubric .card__image {
  opacity: 0;
}
@media (min-width: 768px) {
  .template-homepage .cardsMicrorubric .card__image {
    opacity: 1;
  }
}
.card__image__hover {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.15s linear;
  width: 100%;
}
@media (min-width: 768px) {
  .card__image__hover {
    opacity: 0;
  }
}
.card:hover .card__image__hover {
  background-color: #fff;
  opacity: 1;
}
.card__type {
  background-color: #444;
  color: #fff;
  font-size: 12px;
  left: 0;
  padding: 2px 10px;
  position: absolute;
  text-transform: uppercase;
  top: 20px;
  z-index: 1;
}
.card__type.chitanka {
  background-color: #006d6d;
}
.card__type.reportazhi {
  background-color: #efac00;
}
.card__type.statti {
  background-color: #F05300;
}
.card__type.interviu {
  background-color: #0682B7;
}
.card__type.news {
  background-color: #7FAC00;
}
.card__type.spetsproekty {
  background-color: #214180;
}
.card__type.bez-bromu {
  background-color: #006D6D;
}
.card__type.kolonki {
  background-color: #54336D;
}
.cardsInterview .card__type {
  left: 17px;
  top: 32px;
}
.card__title--video {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.card__title--post {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  position: relative;
}
.card--post:nth-child(4n+1) .card__title--post {
  margin: auto 0 0;
}
.card__title--gallery {
  padding: 20px 20px 0;
}
.cards--equability .card__title {
  font-size: 16px;
}
.cardsType3 .card__title {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
.cardsInterview .card__title {
  font-size: 16px;
  line-height: 1.45;
}
.subscriptionInfo .card__title {
  background-color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin: 0;
  order: 1;
  padding: 10px 15px 20px;
  text-align: center;
  text-transform: uppercase;
}
.cardsMagazine .card__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
}
.cardsProject .card__title {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin: 30px;
  position: relative;
  z-index: 1;
}
.cardsProjectItems .card__title {
  align-items: center;
  color: #fff;
  display: flex;
  flex: 1;
  font-weight: 600;
  justify-content: center;
  margin: 15px;
  order: 1;
  position: relative;
  text-align: center;
  z-index: 1;
}
@media (min-width: 768px) {
  .cardsProjectItems .card__title {
    margin: 30px;
  }
}
.sectionReport .card__title,
.cardsEquality .card__title,
.cardsInequality .card__title {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 30px;
  position: relative;
  z-index: 1;
}
.cardsVideo .card__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  padding: 10px 15px 20px;
}
@media (min-width: 768px) {
  .cardsVideo .card__title {
    padding: 20px 30px 30px;
  }
}
.cardsAlboms .card__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  padding: 10px 15px 5px;
}
@media (min-width: 768px) {
  .cardsAlboms .card__title {
    padding: 20px 30px 5px;
  }
}
.cardsMicrorubric .card__title {
  color: #b6b6b6;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.15;
  margin: 0 0 15px;
}
.template-rubricsIndex .cardsMicrorubric .card__title {
  color: #333;
}
.cardsMicrorubric.cardsAuthors .card__title {
  color: #171A19;
  margin: 0 0 6px;
}
.subscriptionInfo .card__subtitle {
  background-color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  order: 0;
  padding: 20px 15px 0;
  text-align: center;
}
.cardsMagazine .card__subtitle {
  font-size: 1.00012rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 1rem;
  margin-top: 0;
  text-transform: uppercase;
}
.cardsProjectItems .card__subtitle {
  position: relative;
  z-index: 1;
  color: #fff;
  order: 0;
  font-weight: normal;
  margin: 0;
}
.cardsMicrorubric .card__subtitle {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.15;
}
@media (min-width: 768px) {
  .cardsMicrorubric .card__subtitle {
    margin: auto;
    max-width: 180px;
  }
}
.cardsMicrorubric.cardsAuthors .card__subtitle {
  font-weight: normal;
}
.card__body {
  padding: 0 20px;
  margin: 0 0 20px;
  position: relative;
}
.card__body--gallery {
  font-size: 13px;
}
.subscriptionInfo .card__body {
  order: 2;
  padding: 30px 40px 15px;
  margin: 0;
}
.subscriptionInfo .card__body h2 {
  font-size: 28px;
  margin-bottom: 15px;
}
.subscriptionInfo .card__body ol,
.subscriptionInfo .card__body ul {
  margin: 0 0 30px;
  padding: 0 0 0 20px;
}
.cardsMagazine .card__body {
  flex: 1;
}
.cardsAlboms .card__body {
  color: #828282;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0 15px;
  text-align: center;
}
@media (min-width: 768px) {
  .cardsAlboms .card__body {
    margin: 0 0 30px;
  }
}
.card__price {
  margin: 0 10px 15px;
  font-size: 20px;
  font-weight: 600;
}
.card__date {
  color: #b6b6b6;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.15;
  margin: 0 0 15px;
}
.subscriptionInfo .card__footer {
  order: 3;
  padding: 0 40px 40px;
  text-align: center;
}
.card__link {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.cardsVideo .card__link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url('/static/images/icons/play.svg');
  background-position: 50% 35%;
  background-repeat: no-repeat;
  opacity: 0.75;
}
.cardsVideo .card__link:hover::after {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}
.templateMagazineCollection .card__link:hover {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.8);
}
.card__link__text {
  display: none;
}
.card__link:hover .card__link__text {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  vertical-align: middle;
  transition: 0.15s ease;
  min-width: 150px;
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
  font-size: 18px;
  padding: 0.375rem 30px;
}
.card__link:hover .card__link__text:hover {
  background-color: #000;
  color: #fff;
}
body.dark .contactInfo .card__link:hover .card__link__text,
body.template--specProjects .contactInfo .card__link:hover .card__link__text {
  color: #fff;
  padding: 3px 0.75rem;
  border-color: #fff;
}
body.dark .contactInfo .card__link:hover .card__link__text:hover,
body.template--specProjects .contactInfo .card__link:hover .card__link__text:hover {
  background-color: #fff;
  color: #000;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__link:hover .card__link__text {
  color: #000;
  padding: 3px 0.75rem;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__link:hover .card__link__text:hover {
  background-color: #000;
  color: #fff;
}
.nav .card__link:hover .card__link__text {
  min-width: 12em;
  margin: 8px 0;
}
.magazineBody .card__link:hover .card__link__text {
  margin: 0 5px;
  min-width: 100px;
  padding: 8px 0.75rem;
}
.subscriptionInfo .card__link:hover .card__link__text {
  text-transform: uppercase;
}
.cookies .card__link:hover .card__link__text,
body.dark aside .card__link:hover .card__link__text,
.section--lektorii .card__link:hover .card__link__text,
.section--photos .card__link:hover .card__link__text,
.section--videos .card__link:hover .card__link__text,
.section--reportazhi .card__link:hover .card__link__text,
.section--chitanka .card__link:hover .card__link__text {
  color: #fff;
  border-color: #fff;
}
.cookies .card__link:hover .card__link__text:hover,
body.dark aside .card__link:hover .card__link__text:hover,
.section--lektorii .card__link:hover .card__link__text:hover,
.section--photos .card__link:hover .card__link__text:hover,
.section--videos .card__link:hover .card__link__text:hover,
.section--reportazhi .card__link:hover .card__link__text:hover,
.section--chitanka .card__link:hover .card__link__text:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.cookies .card__link:hover .card__link__text {
  min-width: 90px;
}
.card__link:hover .card__link__text:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.cookies .card__link:hover .card__link__text,
body.dark .contactInfo .card__link:hover .card__link__text,
body.template--specProjects .contactInfo .card__link:hover .card__link__text {
  background-color: #F05300;
  border-color: #F05300;
}
.cookies .card__link:hover .card__link__text:hover,
body.dark .contactInfo .card__link:hover .card__link__text:hover,
body.template--specProjects .contactInfo .card__link:hover .card__link__text:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__link:hover .card__link__text {
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__link:hover .card__link__text:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.card__button {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  vertical-align: middle;
  transition: 0.15s ease;
  min-width: 150px;
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
  font-size: 18px;
  padding: 0.375rem 30px;
  margin-bottom: 30px;
}
.card__button:hover {
  background-color: #000;
  color: #fff;
}
body.dark .contactInfo .card__button,
body.template--specProjects .contactInfo .card__button {
  color: #fff;
  padding: 3px 0.75rem;
  border-color: #fff;
}
body.dark .contactInfo .card__button:hover,
body.template--specProjects .contactInfo .card__button:hover {
  background-color: #fff;
  color: #000;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__button {
  color: #000;
  padding: 3px 0.75rem;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__button:hover {
  background-color: #000;
  color: #fff;
}
.nav .card__button {
  min-width: 12em;
  margin: 8px 0;
}
.magazineBody .card__button {
  margin: 0 5px;
  min-width: 100px;
  padding: 8px 0.75rem;
}
.subscriptionInfo .card__button {
  text-transform: uppercase;
}
.cookies .card__button,
body.dark aside .card__button,
.section--lektorii .card__button,
.section--photos .card__button,
.section--videos .card__button,
.section--reportazhi .card__button,
.section--chitanka .card__button {
  color: #fff;
  border-color: #fff;
}
.cookies .card__button:hover,
body.dark aside .card__button:hover,
.section--lektorii .card__button:hover,
.section--photos .card__button:hover,
.section--videos .card__button:hover,
.section--reportazhi .card__button:hover,
.section--chitanka .card__button:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.cookies .card__button {
  min-width: 90px;
}
.card__button:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.cookies .card__button,
body.dark .contactInfo .card__button,
body.template--specProjects .contactInfo .card__button {
  background-color: #F05300;
  border-color: #F05300;
}
.cookies .card__button:hover,
body.dark .contactInfo .card__button:hover,
body.template--specProjects .contactInfo .card__button:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__button {
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__button:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.cardsMagazine .card__button {
  border-color: #f05300;
}
.card:hover .card__button {
  visibility: hidden;
}
.card__readmore {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  vertical-align: middle;
  transition: 0.15s ease;
  min-width: 150px;
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
  font-size: 18px;
}
.card__readmore:hover {
  background-color: #000;
  color: #fff;
}
body.dark .contactInfo .card__readmore,
body.template--specProjects .contactInfo .card__readmore {
  color: #fff;
  padding: 3px 0.75rem;
  border-color: #fff;
}
body.dark .contactInfo .card__readmore:hover,
body.template--specProjects .contactInfo .card__readmore:hover {
  background-color: #fff;
  color: #000;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__readmore {
  color: #000;
  padding: 3px 0.75rem;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__readmore:hover {
  background-color: #000;
  color: #fff;
}
.nav .card__readmore {
  min-width: 12em;
  margin: 8px 0;
}
.magazineBody .card__readmore {
  margin: 0 5px;
  min-width: 100px;
  padding: 8px 0.75rem;
}
.subscriptionInfo .card__readmore {
  text-transform: uppercase;
}
.cookies .card__readmore,
body.dark aside .card__readmore,
.section--lektorii .card__readmore,
.section--photos .card__readmore,
.section--videos .card__readmore,
.section--reportazhi .card__readmore,
.section--chitanka .card__readmore {
  color: #fff;
  border-color: #fff;
}
.cookies .card__readmore:hover,
body.dark aside .card__readmore:hover,
.section--lektorii .card__readmore:hover,
.section--photos .card__readmore:hover,
.section--videos .card__readmore:hover,
.section--reportazhi .card__readmore:hover,
.section--chitanka .card__readmore:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.cookies .card__readmore {
  min-width: 90px;
}
.card__readmore:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.cookies .card__readmore,
body.dark .contactInfo .card__readmore,
body.template--specProjects .contactInfo .card__readmore {
  background-color: #F05300;
  border-color: #F05300;
}
.cookies .card__readmore:hover,
body.dark .contactInfo .card__readmore:hover,
body.template--specProjects .contactInfo .card__readmore:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__readmore {
  color: #fff;
  background-color: #F05300;
  border-color: #F05300;
}
body:not(.template--specProjects):not(.dark) .contactInfo .card__readmore:hover {
  color: #fff;
  background-color: #ca4600;
  border-color: #bd4100;
}
.subscriptionInfo .card__readmore {
  padding: 0.375rem 30px;
  border-color: #f05300;
}
.galleryPhoto {
  column-gap: 30px;
  columns: 1;
}
@media (min-width: 440px) {
  .galleryPhoto {
    columns: 2;
  }
}
@media (min-width: 768px) {
  .galleryPhoto {
    columns: 3;
  }
}
.galleryPhotoItem {
  display: inline-block;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}
.galleryPhotoItem picture {
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  display: block;
}
.galleryPhotoItem figcaption {
  border: 0;
}
.galleryPhotoItem strong {
  font-weight: 500;
}
.galleryPhotoItem__img {
  border-radius: 2px;
  display: block;
  height: auto;
  width: 100%;
}
.galleryPhotoItem__link {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  background-image: url("/static/images/icons/arrow.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  display: block;
  height: 66px;
  margin-top: -10px;
  opacity: 0.85;
  outline: none;
  position: absolute;
  top: 50%;
  width: 66px;
  z-index: 1080;
}
.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  opacity: 1;
}
.lg-actions .lg-next {
  right: 20px;
}
.lg-actions .lg-prev {
  left: 20px;
  transform: rotate(-180deg);
}
@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  animation: lg-left-end 0.3s;
  position: relative;
}
.lg-toolbar {
  background-color: rgba(0, 0, 0, 0.45);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1082;
}
.lg-toolbar .lg-icon {
  background: none;
  border: none;
  box-shadow: none;
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  outline: medium none;
  padding: 10px 0;
  text-align: center;
  text-decoration: none !important;
  transition: color 0.2s linear;
  width: 50px;
}
.lg-toolbar .lg-close {
  background-image: url("/static/images/icons/close.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
}
.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}
.lg-sub-html h4 {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}
#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}
.lg-toolbar,
.lg-prev,
.lg-next {
  opacity: 1;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}
.lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}
.lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}
.lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transform-origin: 50% 50%;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  transition-duration: 0s !important;
}
.lg-outer.lg-thumb-open .lg-thumb-outer {
  transform: translate3d(0, 0%, 0);
}
.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}
.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}
@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}
.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}
.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none;
}
.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}
.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}
.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}
.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}
.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}
.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}
.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}
.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}
.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}
.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}
.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}
.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}
.lg-outer .lg-pager:hover,
.lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}
.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}
.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
}
.lg-group:before,
.lg-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.lg-group:after {
  clear: both;
}
.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: none;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item.lg-prev-slide,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}
.lg-outer .lg-item {
  display: none !important;
}
.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}
.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-empty-html {
  display: none;
}
.lg-outer.lg-hide-download #lg-download {
  display: none;
}
.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-backdrop.in {
  opacity: 1;
}
.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.swiper-slide {
  overflow: hidden;
}
@media (min-width: 600px) {
  .slick-slide {
    margin: 0 30px;
  }
}
@media (min-width: 1024px) {
  .slick-slide {
    margin: 0 60px;
  }
}
.breadcrumbs {
  margin: auto;
  max-width: 1140px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto 30px;
  position: relative;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .breadcrumbs {
    padding: 0 30px;
  }
}
@media (min-width: 768px) {
  .breadcrumbs--newsItem {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 7fr 2fr;
  }
}
@media (min-width: 1200px) {
  .breadcrumbs--newsItem {
    grid-gap: 60px;
  }
}
@media (min-width: 768px) {
  .breadcrumbs {
    margin: 0 auto 40px;
  }
}
@media (min-width: 1230px) {
  .breadcrumbs {
    box-sizing: initial;
  }
}
body.dark .breadcrumbs,
.blockHero--background .breadcrumbs {
  color: #fff;
}
.breadcrumb__items {
  display: inline-block;
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.breadcrumb__items svg {
  margin: 0 5px;
}
.breadcrumb__items li {
  display: inline-block;
  vertical-align: middle;
}
.breadcrumb__items a {
  color: rgba(0, 0, 0, 0.4);
  text-decoration: none;
}
.breadcrumb__items a:hover {
  color: #000;
}
body.dark .breadcrumb__items a,
.template--specProjects .breadcrumb__items a,
.templateSubscription .breadcrumb__items a,
.blockHero--background .breadcrumb__items a {
  color: rgba(255, 255, 255, 0.8);
}
body.dark .breadcrumb__items a:hover,
.template--specProjects .breadcrumb__items a:hover,
.templateSubscription .breadcrumb__items a:hover,
.blockHero--background .breadcrumb__items a:hover {
  color: #fff;
}
