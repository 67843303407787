.page__header {
    left: 0;
    padding: 14px 0;
    position: fixed;
    top: 0;
    transition: all 0.4s;
    width: 100%;
    z-index: 4;
    body:not(.template--specProjects):not(.dark) & {
        background-color: fade(#fff, 98%);
        backdrop-filter: blur(2px);
        box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.1);
    }
    @media (min-width: 860px) {
        padding: 16px 0 8px;
    }
    // body.dark & {
    //     background-color: fade(#000, 70%);
    // }
    &.open {
        backdrop-filter: blur(2px);
        background-color: fade(#fff, 98%);
        bottom: 0;
        top: 0 !important;
        body.dark &,
        .template--specProjects & {
            background-color: fade(#000, 98%);
        }
    }
    &.visual {
        body.dark &,
        .template--specProjects & {
            backdrop-filter: blur(2px);
            background-color: fade(#000, 95%);
        }
    }
}

// Logo
.logo {
    display: block;
    width: auto;
    max-height: 32px;
    @media (min-width: 860px) {
        max-height: 53px;
        margin-bottom: 12px;
    }
}

// Navbar
.navSection {
    display: none;
    width: 100%;
    padding: 35px 0;
    @media (min-width: 860px) {
        display: block;
        width: auto;
        padding: 0;
        max-width: calc(100% - 300px);
    }
    .open & {
        display: block;
    }
}
.navbar {
    .container;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.contactInfo {
    align-items: center;
    color: #DCDEDE;
    font-size: 12px;
    font-weight: 300;
    justify-content: flex-end;
    letter-spacing: 0.2px;
    line-height: 14px;
    list-style: none;
    margin: 0 0 6px;
    padding: 0;
    display: none;
    @media (min-width: 860px) {
        display: flex;
    }
    // .open & {
    //     display: block;
    //     margin: 20px 0 40px 10px;
    // }
    li {
        @media (min-width: 860px) {
            margin-left: 18px;
        }
        // .open & {
        //     margin-bottom: 10px;
        // }
    }
    // a {
    //     color: #DCDEDE;
    //     &:hover {
    //         color: @primary-color;
    //     }
    // }
    // svg {
    //     display: block;
    // }
    &__search {
        padding-right: 18px;
        border-right: 1px solid #828282;
    }
}
.nav {
    font-size: 0.875rem;
    font-weight: 600;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    @media (min-width: 860px) {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        text-align: right;
    }
    .open & {
        text-align: center;
    }
    &__item {
        @media (min-width: 860px) {
            margin-left: 15px;
        }
        // &--dropdown {
        //     ul {
        //         display: none;
        //     }
        //     > a {
        //         &::after {
        //             display: inline-block;
        //             width: 0;
        //             height: 0;
        //             margin-left: .255em;
        //             vertical-align: .255em;
        //             content: "";
        //             border-top: 4px solid;
        //             border-right: 4px solid transparent;
        //             border-left: 4px solid transparent;
        //         }
        //     }
        // }
        &--btn {
            @media (min-width: 860px) {
                display: none;
            }
        }
    }
    &__link {
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        position: relative;
        body.dark &,
        .template--specProjects & {
            color: fade(#fff, 75%);
        }
        &::before {
            content: '';
            position: absolute;
            width: calc(100% - 1rem);
            height: 0.125rem;
            left: 0.5rem;
            bottom: .35rem;
            transform: scaleX(0);
            transform-origin: left;
            will-change: transform;
            transition: transform .3s ease;
            background-color: @primary-color;
            opacity: .85;
        }
        &.active,
        &.ancestor,
        &:hover {
            color: rgba(0, 0, 0, 0.7);
            body.dark &,
            .template--specProjects & {
                color: #fff;
            }
            &::before {
                transform: scaleX(1);
            }
        }
        svg {
            max-width: 10px;
            height: auto;
            margin-left: 6px;
            display: block;
            margin-bottom: 3px;
            opacity: 0.8;
        }
    }
}
.mainmenu__dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (min-width: 860px) {
        margin-left: 10px;
    }
    &.open,
    &:hover {
        ul {
            display: block;
            @media (min-width: 860px) {
                position: absolute;
                right: 0;
                top: 100%;
            }
        }
    }
    ul {
        list-style: none;
        padding: 10px 0;
        margin: 0;
        @media (min-width: 860px) {
            box-shadow: 0px 10px 20px -10px fade(#000, 25%);
            background-color: #fff;
            display: none;
            min-width: 140px;
        }
        li.active a {
            color: @primary-color;
        }
        a {
            color: rgba(0,0,0,.5);
            display: block;
            padding: 0.5rem;
            white-space: nowrap;
            @media (min-width: 860px) {
                padding: 2px 15px;
                text-align: right;
            }
            &:hover {
                color: @primary-color;
            }
        }
    }
}
.mainmenu__dropdown__btn {
    padding: 15px 5px;
    background: transparent;
    border: 0;
    display: none;
    @media (min-width: 860px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.open,
    &:hover {
        cursor: pointer;
        span {
            background-color: @primary-color;
        }
    }
    span {
        display: block;
        height: 6px;
        width: 6px;
        background-color: fade(#000, 70%);
        margin: 0 1px 0 2px;
        border-radius: 50%;
        body.dark &,
        .template--specProjects & {
            background-color: fade(#fff, 70%);
        }
    }
}

// Drop Down Menu
.nav__item {
    position: relative;
    &:hover {
        ul {
            display: block;
            @media (min-width: 860px) {
                position: absolute;
                left: 0;
            }
        }
    }
    ul {
        background-color: #fff;
        list-style: none;
        padding: 10px 0;
        margin: 0 0 10px;
        box-shadow: 0px 10px 20px -10px fade(#000, 25%);
        @media (min-width: 860px) {
            display: none;
            min-width: 140px;
        }
        li.active a {
            color: @primary-color;
        }
        a {
            display: block;
            padding: 2px 15px;
            color: rgba(0,0,0,.5);
            @media (min-width: 860px) {
                text-align: left;
            }
            &:hover {
                color: @primary-color;
            }
        }
    }
}
.navSectionMobile {
    margin: 0 -10px 0 0;
    @media (min-width: 860px) {
        display: none;
    }
}
.navbar__toggle {
    align-items: center;
    background: transparent;
    border: 0;
    color: #000;
    cursor: pointer;
    outline: none;
    padding: 10px;
    z-index: 2;
    body.dark &,
    .template--specProjects & {
        color: #fff;
    }
    .open &--nav {
        color: @primary-color;
    }
    // .template-homepage & {
    //     color: #fff;
    // }
    // .template-homepage .open &,
    // .template-homepage .visual & {
    //     color: @black;
    // }
    // @media (min-width: 768px) {
    //     display: none;
    // }
    // &__text {
    //     display: none;
    //     .open & {
    //         display: block;
    //         margin-right: 8px;
    //     }
    // }
    img {
        display: block;
    }
}
.header__socialLink {
    color: #828282;
    .footer__social & {
        color: #828282;
    }
    &:hover {
        color: @primary-color;
        .footer__social & {
            color: #fff;
        }
    }
}
