html {
    font-size: @global-font-size;
    height: 100%;
}
body {
    background-color: #f4f4f4;
    box-sizing: border-box;
    color: @global-font-color;
    display: flex;
    flex-direction: column;
    font-family: @sans-serif-fonts;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    min-height: 100%;
    padding: 75px 0 0;
    @media (min-width: 860px) {
        padding-top: 105px;
    }
    &.template--specProjects {
        background: #000;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.07%, #000000 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('/static/images/backgrounds/subscription.jpg');
        background-attachment: fixed;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
    }
    &.dark {
        background-color: fade(#000, 90%);
        color: #fff;
    }
    &.templateSubscription,
    &.template-specProjectPage,
    &.template-article,
    &.template-searchresults,
    &.template-homepage {
        padding: 65px 0 0;
        @media (min-width: 860px) {
            padding-top: 95px;
        }
    }
    &.templateSubscription,
    &.templateVideoItem {
        @media (min-width: 860px) {
            padding-top: 95px;
        }
    }
    &.open {
        overflow: hidden;
    }
}
h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 30px;
    text-align: center;
    @media (min-width: @tablet) {
        font-size: 35px;
        line-height: 42px;
        margin: 0 0 40px;
    }
    .template-magazine &,
    .template--newsItem & {
        text-align: left;
    }
    .subscriptionInfo & {
        z-index: 1;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 50px;
        padding: 0 15px;
    }
}
h2 {
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 30px;
    .blockText &:not(:first-child) {
        margin: 50px 0 30px;
    }
}
h3 {
    margin-top: 0;
}
h4 {
    margin-top: 0;
}
h5 {
    margin-bottom: 1rem;
    font-size: 1.00012rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    text-transform: uppercase;
}
a {
    color: @primary-color;
    transition: all 0.2s;
    text-decoration: none;
}
.btn {
    // border-bottom: 1px solid @primary-color;
    // color: @primary-color;
    // padding: 5px 2px;
    // text-decoration: none;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #000;
    color: #000;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    vertical-align: middle;
    transition: 0.15s ease;
    min-width: 150px;
    &:hover {
        background-color: #000;
        color: #fff;
    }
    body.dark .contactInfo &,
    body.template--specProjects .contactInfo & {
        color: #fff;
        padding: 3px 0.75rem;
        border-color: #fff;
        &:hover {
            background-color: #fff;
            color: #000;
        }
    }
    body:not(.template--specProjects):not(.dark) .contactInfo & {
        color: #000;
        padding: 3px 0.75rem;
        &:hover {
            background-color: #000;
            color: #fff;
        }
    }
    .nav & {
        min-width: 12em;
        margin: 8px 0;
    }
    .magazineBody & {
        margin: 0 5px;
        min-width: 100px;
        padding: 8px 0.75rem;
    }
    .subscriptionInfo & {
        text-transform: uppercase;
    }
    .cookies &,
    body.dark aside &,
    .section--lektorii &,
    .section--photos &,
    .section--videos &,
    .section--reportazhi &,
    .section--chitanka & {
        color: #fff;
        border-color: #fff;
        &:hover {
            color: #000;
            background-color: #fff;
            border-color: #fff;
        }
    }
    .cookies & {
        min-width: 90px;
    }
}
.btn--primary {
    color: #fff;
    background-color: @primary-color;
    border-color: @primary-color;
    &:hover {
        color: #fff;
        background-color: #ca4600;
        border-color: #bd4100;
    }
    .cookies &,
    body.dark .contactInfo &,
    body.template--specProjects .contactInfo & {
        background-color: @primary-color;
        border-color: @primary-color;
        &:hover {
            color: #fff;
            background-color: #ca4600;
            border-color: #bd4100;
        }
    }
    body:not(.template--specProjects):not(.dark) .contactInfo & {
        color: #fff;
        background-color: @primary-color;
        border-color: @primary-color;
        &:hover {
            color: #fff;
            background-color: #ca4600;
            border-color: #bd4100;
        }
    }
    // .magazineBody & {
    //     font-size: 18px;
    //     padding: 0.375rem 0.75rem;
    // }
}
// .btn--link {
//     color: @primary-color;
//     text-decoration: underline;
//     &:hover {
//         background-color: transparent;
//         color: #ca4600;
//     }
// }
.btnGroup {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
        margin-bottom: 10px;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
p {
    margin: 0 0 25px;
}
figure {
    display: table;
    margin: 0;
    position: relative;
    text-align: center;
    .blockSlider__item & {
        margin: 0 auto;
    }
    .blockEmbed & {
        width: 100%;
    }
}
figcaption {
    border-bottom: 1px solid #e5e5e5;
    caption-side: bottom;
    display: table-caption;
    font-size: 13px;
    line-height: 20px;
    margin: 0 15px;
    max-width: 660px;
    padding: 10px 0;
    text-align: left;
    .blockEmbed & {
        margin: 0 auto;
        text-align: center;
    }
    span {
        color: #999999;
        display: block;
        font-family: Arial, Helvetica, Geneva, sans-serif;
        font-style: italic;
        font-weight: normal;
    }
    p {
        margin-bottom: 4px;
    }
}

blockquote {
    box-sizing: border-box;
    margin: 60px auto 70px;
    max-width: 1000px;
    padding: 70px 15px 0;
    text-align: center;
    position: relative;
    font-size: 22px;
    @media (min-width: @tablet) {
        padding-left: 100px;
        padding-right: 100px;
    }
    &::after {
        background-color: #f4f4f4;
        padding: 0 20px;
        color: @primary-color;
        content: '\201D';
        display: inline-block;
        font-family: serif;
        font-size: 120px;
        font-style: normal;
        font-weight: bold;
        line-height: 1;
        position: absolute;
        top: 0;
        left: calc(50% - 45px);
        z-index: -1;
        body.dark & {
            background-color: #191919;
        }
    }
    &::before {
        border-top: 1px solid #f05300;
        content: '';
        left: calc(50% - 115px);
        opacity: 0.5;
        position: absolute;
        top: 22px;
        width: 230px;
        z-index: -2;
    }
    p {
        position: relative;
        z-index: 1;
        font-style: italic;
    }
    footer {
        position: relative;
        z-index: 1;
        font-style: normal;
        text-align: right;
        font-size: 16px;
    }
}



iframe {
    margin-bottom: 30px;
}
main {
    flex: 1;
    margin-bottom: 60px;
    .template-rubricsIndex &,
    .templateVideoIndex &,
    .templateSubscription & {
        margin-bottom: 0;
    }
    .template-homepage & {
        margin-bottom: 0;
    }
    .template-articlesIndex & {
        margin-bottom: 0;
    }
    header {
        text-align: center;
        margin-bottom: 60px;
    }
}
.container {
    margin: auto;
    max-width: 1140px;
    padding: 0 15px;
    @media (min-width: @tablet) {
        padding: 0 30px;
    }
    &--newsItem {
        @media (min-width: @tablet) {
            display: grid;
            grid-gap: 30px;
            grid-template-columns: 7fr 2fr;
        }
        @media (min-width: 1200px) {
            grid-gap: 60px;
        }
    }
}
.hidden {
    display: none;
}

.richtext-image {
    display: block;
    height: auto;
    max-width: 100%;
    &.full-width {
        margin: 0 auto 30px;
    }
    &.right {
        margin: 0 0 30px;
        @media (min-width: @tablet) {
            float: right;
            margin: 5px -100px 30px 30px;
        }
    }
    &.left {
        margin: 0 0 30px;
        @media (min-width: @tablet) {
            float: left;
            margin: 5px 30px 30px -100px;
        }
    }
}

.textLeft {
    text-align: left !important;
}

.arrowLeft {
    transform: rotate(180deg);
}

// Table
table {
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;
    caption {
        font-weight: bold;
        padding: 0 0 15px;
    }
    td,
    th {
        padding: .75rem;
        vertical-align: top;
        border: 1px solid fade(#000, 30%);
    }
}
.htLeft {
    text-align: left;
}
.htRight {
    text-align: right;
}
.htCenter {
    text-align: center;
}
.htJustify {
    text-align: justify;
}
.htTop {
    vertical-align: top;
}
.htMiddle {
    vertical-align: middle;
}
.htBottom {
    vertical-align: bottom;
}
