.cardsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
    @media (min-width: 960px) {
        grid-gap: 50px;
        grid-template-columns: 2fr 1fr;
        margin-bottom: 50px;
    }
}
.cardsGroup {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    @media (min-width: 680px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 960px) {
        grid-template-columns: 1fr;
    }
}
.cards {
    // position: relative;
    &--video {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        @media (min-width: @tablet) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &--inequality {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &--equability {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .subscriptionInfo & {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        @media (min-width: @tablet) {
            grid-template-columns: 1fr 1fr;
        }
        @media (min-width: 830px) {
            grid-gap: 30px;
        }
    }
    .template-searchresults & {
        background-color: #f4f4f4;
        position: relative;
        @media (min-width: @tablet) {
            padding: 30px 30px 0;
        }
    }
    .sectionReport & {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        margin-bottom: 30px;
        @media (min-width: 680px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
        }
    }
}
.cardsProjectItems {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    @media (min-width: @tablet) {
        grid-gap: 30px;
    }
    &--col2 {
        @media (min-width: @tablet) {
            grid-template-columns: 1fr 1fr;
        }
    }
}
.cardsMagazine {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    @media (min-width: 480px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: @tablet) {
        grid-template-columns: 1fr 1fr 1fr;
        // grid-gap: 15px;
    }
    @media (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
    }
}
// .cardsInequality {
//     // display: flex;
//     // flex-wrap: wrap;
//     // justify-content: space-between;
//     display: grid;
//     grid-gap: 20px;
//     grid-template-columns: 2fr 3fr;
//     // grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
//     > div:nth-child(3n+1) {
//         grid-column: span 2;
//     }
// }
.cardsInterview {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    // grid-gap: 20px;
    .section--articles & {
        margin-bottom: 50px;
    }
}
.cardsProject {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin: auto;
}
// .cardsEquality {
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-gap: 15px;
//     @media (min-width: 480px) {
//         grid-template-columns: 1fr 1fr;
//     }
//     @media (min-width: @tablet) {
//         grid-template-columns: 1fr 1fr 1fr;
//     }
//     @media (min-width: 1200px) {
//         grid-template-columns: 1fr 1fr 1fr 1fr;
//         grid-gap: 30px;
//     }
// }
.cardsVideo {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    @media (min-width: 480px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: @tablet) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1200px) {
        grid-gap: 30px;
    }
    .templateVideoIndex & {
        margin-bottom: 50px;
    }
}
.cardsAlboms {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    @media (min-width: 480px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: @tablet) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1200px) {
        grid-gap: 30px;
    }
}
.cardsMicrorubric {
    margin-bottom: 60px;
    grid-gap: 30px 0;
    @media (min-width: @tablet) {
        grid-gap: 60px 30px;
    }
}

.card {
    position: relative;
    overflow: hidden;
    .dark & {
        color: @global-font-color;
    }
    &--video {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    }
    .subscriptionInfo & {
        background-color: fade(#fff, 80%);
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
    }
    .cardsMagazine & {
        text-align: center;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        align-items: center;
    }
    .cardsVideo & {
        text-align: center;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        align-items: center;
    }
    .cardsAlboms & {
        text-align: center;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        align-items: center;
    }
    .cardsInequality & {
        align-items: flex-end;
        background-color: #777;
        display: flex;
        min-height: 262px;
        width: 100%;
        transition: 0.2s ease;
        &:hover {
            background-color: #222;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        }
    }
    .cardsProjectItems & {
        background-color: #777;
        display: flex;
        min-height: 262px;
        flex-direction: column;
        padding: 15px;
        @media (min-width: @tablet) {
            padding: 15px 30px 30px;
        }
        &:hover {
            background-color: #222;
            .card__image {
                filter: brightness(20%);
            }
        }
    }
    .cardsType3 & {
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        padding: 17px;
        transition: 0.15s ease-out;
        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        }
    }
    .cardsInterview & {
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        padding: 17px;
        transition: 0.15s ease-out;
        display: flex;
        flex-direction: column;
        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        }
    }
    .cardsType3 & {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        box-sizing: border-box;
    }
    .sectionReport &,
    .cardsEquality & {
        align-items: flex-end;
        background-color: #777;
        display: flex;
        min-height: 262px;
        &:hover {
            background-color: #222;
        }
    }
    .cardsProject & {
        align-items: center;
        background-color: #777;
        display: flex;
        justify-content: center;
        min-height: 400px;
    }
    .cardsMicrorubric & {
        padding: 0 15px;
        text-align: center;
    }
}

.card__imageVisual {
    overflow: hidden;
    .cardsMagazine & {
        margin: 0 0 15px;
    }
    .cardsType3 & {
        flex: 1;
    }
    .cardsInterview & {
        flex: 1;
    }
    .cardsMicrorubric & {
        align-items: center;
        border-radius: 50%;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        display: flex;
        height: 140px;
        justify-content: center;
        margin: 0 auto 15px;
        overflow: hidden;
        position: relative;
        width: 140px;
    }
}
.card__image {
    display: block;
    transition: 0.3s ease;
    .card:hover & {
        transform: scale(1.05);
    }
    .cardsAlboms & {
        max-width: 100%;
        height: auto;
    }
    .cardsVideo & {
        max-width: 100%;
        height: auto;
    }
    .cardsInterview &,
    .cardsMagazine & {
        max-width: 100%;
        height: auto;
    }
    .sectionReport &,
    .cardsEquality &,
    .cardsInequality & {
        display: block;
        filter: brightness(45%);
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s ease-out;
        width: 100%;
    }
    .cardsType3 & {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: relative;
        margin: 30px auto 20px;
        border: 4px solid #006D6D;
        display: block;
        // height: 100%;
        // left: 0;
        object-fit: cover;
        object-position: 50% 50%;
        // position: absolute;
        // right: 0;
        // top: 0;
        // transition: 0.3s ease-out;
        // width: 100%;
    }
    .cardsType3 .card:hover & {
        transform: scale(1);
        border-color: @primary-color;
    }
    .sectionReport .card.active &,
    .sectionReport .card:hover &,
    .cardsProject .card:hover &,
    .cardsEquality .card:hover &,
    .cardsInequality .card:hover & {
        filter: brightness(35%);
    }
    .cardsInterview & {
        margin-bottom: 17px;
    }
    .cardsProjectItems & {
        display: block;
        filter: brightness(45%);
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s ease-out;
        width: 100%;
        &:hover {
            filter: brightness(20%);
        }
    }
    .cardsProject & {
        display: block;
        filter: brightness(45%);
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s ease-out;
        width: 100%;
        &:hover {
            filter: brightness(20%);
        }
    }
    .template-rubricsCollection .cardsMicrorubric &,
    .template-rubricsIndex .cardsMicrorubric & {
        bottom: 0;
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 0.15s linear;
        width: 100%;
    }
    .template-homepage .cardsMicrorubric & {
        opacity: 0;
        @media (min-width: 768px) {
            opacity: 1;
        }
    }
}
.card__image__hover {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.15s linear;
    width: 100%;
    @media (min-width: 768px) {
        opacity: 0;
    }
    .card:hover & {
        background-color: #fff;
        opacity: 1;
    }
}
.card__type {
    background-color: #444;
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 2px 10px;
    position: absolute;
    text-transform: uppercase;
    top: 20px;
    z-index: 1;
    &.chitanka {
        background-color: #006d6d;
    }
    &.reportazhi {
        background-color: #efac00;
    }
    &.statti {
        background-color: #F05300;
    }
    &.interviu {
        background-color: #0682B7;
    }
    &.news {
        background-color: #7FAC00;
    }
    &.spetsproekty {
        background-color: #214180;
    }
    &.bez-bromu {
        background-color: #006D6D;
    }
    &.kolonki {
        background-color: #54336D;
    }
    .cardsInterview & {
        left: 17px;
        top: 32px;
    }
    // .card:not(.card--stylish) & {
    //     color: #fff;
    // }
    // &::after {
    //     border-bottom: 4px solid #006D6D;
    //     content: "";
    //     display: block;
    //     margin-right: 10px;
    //     position: absolute;
    //     right: 100%;
    //     top: 10px;
    //     width: 100%;
    // }
}
.card__title {
    // margin: 0;
    // padding: 20px;
    &--video {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }
    &--post {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        position: relative;
        .card--post:nth-child(4n+1) & {
            margin: auto 0 0;
        }
    }
    &--gallery {
        padding: 20px 20px 0;
    }
    .cards--equability & {
        font-size: 16px;
    }
    .cardsType3 & {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }
    .cardsInterview & {
        // padding: 0 17px 13px;
        font-size: 16px;
        line-height: 1.45;
    }
    .subscriptionInfo & {
        background-color: #fff;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        margin: 0;
        order: 1;
        padding: 10px 15px 20px;
        text-align: center;
        text-transform: uppercase;
    }
    .cardsMagazine & {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin: 0;
    }
    .cardsProject & {
        color: #fff;
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        margin: 30px;
        position: relative;
        z-index: 1;
    }
    .cardsProjectItems & {
        align-items: center;
        color: #fff;
        display: flex;
        flex: 1;
        font-weight: 600;
        justify-content: center;
        margin: 15px;
        order: 1;
        position: relative;
        text-align: center;
        z-index: 1;
        @media (min-width: @tablet) {
            margin: 30px;
        }
    }
    .sectionReport &,
    .cardsEquality &,
    .cardsInequality & {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin: 30px;
        position: relative;
        z-index: 1;
    }
    .cardsVideo & {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin: 0;
        padding: 10px 15px 20px;
        @media (min-width: @tablet) {
            padding: 20px 30px 30px;
        }
    }
    .cardsAlboms & {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin: 0;
        padding: 10px 15px 5px;
        @media (min-width: @tablet) {
            padding: 20px 30px 5px;
        }
    }
    .cardsMicrorubric & {
        color: #b6b6b6;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.15;
        margin: 0 0 15px;
        .template-rubricsIndex & {
            color: #333;
        }
    }
    .cardsMicrorubric.cardsAuthors & {
        color: @global-font-color;
        margin: 0 0 6px;
    }
}
.card__subtitle {
    .subscriptionInfo & {
        background-color: #fff;
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        margin: 0;
        order: 0;
        padding: 20px 15px 0;
        text-align: center;
    }
    .cardsMagazine & {
        font-size: 1.00012rem;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 1rem;
        margin-top: 0;
        text-transform: uppercase;
    }
    .cardsProjectItems & {
        position: relative;
        z-index: 1;
        color: #fff;
        order: 0;
        font-weight: normal;
        margin: 0;
        // padding: 15px;
        // @media (min-width: @tablet) {
        //     padding: 15px 30px 30px;
        // }
    }
    .cardsMicrorubric & {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.15;
        @media (min-width: @tablet) {
            margin: auto;
            max-width: 180px;
        }
    }
    .cardsMicrorubric.cardsAuthors & {
        font-weight: normal;
    }
}
.card__body {
    padding: 0 20px;
    margin: 0 0 20px;
    position: relative;
    &--gallery {
        font-size: 13px;
    }
    .subscriptionInfo & {
        order: 2;
        padding: 30px 40px 15px;
        margin: 0;
        h2 {
            font-size: 28px;
            margin-bottom: 15px;
        }
        ol, ul {
            margin: 0 0 30px;
            padding: 0 0 0 20px;
        }
    }
    .cardsMagazine & {
        flex: 1;
    }
    .cardsAlboms & {
        color: #828282;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        padding: 0 15px;
        text-align: center;
        @media (min-width: @tablet) {
            margin: 0 0 30px;
        }
    }
}
.card__price {
    margin: 0 10px 15px;
    font-size: 20px;
    font-weight: 600;
}
.card__date {
    color: #b6b6b6;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.15;
    margin: 0 0 15px;
}
.card__footer {
    .subscriptionInfo & {
        order: 3;
        padding: 0 40px 40px;
        text-align: center;
    }
}
.card__link {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    .cardsVideo & {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: url('/static/images/icons/play.svg');
            background-position: 50% 35%;
            background-repeat: no-repeat;
            opacity: 0.75;
        }
        &:hover::after {
            backdrop-filter: blur(2px);
            background-color: fade(#fff, 80%);
            opacity: 1;
        }
    }
    .templateMagazineCollection &:hover {
        backdrop-filter: blur(2px);
        background-color: fade(#fff, 80%);
    }

}
.card__link__text {
    display: none;
    .card__link:hover & {
        .btn;
        .btn--primary;
        font-size: 18px;
        padding: .375rem 30px;
    }
}

.card__button {
    .btn;
    .btn--primary;
    font-size: 18px;
    padding: .375rem 30px;
    margin-bottom: 30px;
    .cardsMagazine & {
        border-color: #f05300;
    }
    .card:hover & {
        visibility: hidden;
    }
}

.card__readmore {
    .btn;
    .btn--primary;
    font-size: 18px;
    .subscriptionInfo & {
        padding: .375rem 30px;
        border-color: #f05300;
    }
}
