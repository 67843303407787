.galleryPhoto {
    column-gap: 30px;
    columns: 1;
    @media (min-width: 440px) {
        columns: 2;
    }
    @media (min-width: @tablet) {
        columns: 3;
    }
}
.galleryPhotoItem {
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    picture {
        overflow: hidden;
        position: relative;
        border-radius: 2px;
        display: block;
    }
    figcaption {
        border: 0;
    }
    strong {
        font-weight: 500;
    }
}
.galleryPhotoItem__img {
    border-radius: 2px;
    display: block;
    height: auto;
    width: 100%;
    // transition: 0.3s ease-out;
    // transform: scale(1);
    // .galleryPhotoItem:hover & {
    //     transform: scale(1.04);
    // }
}
.galleryPhotoItem__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
