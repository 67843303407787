.l {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
}
.l2 {
    @media (min-width: 680px) {
        grid-template-columns: 1fr 1fr;
    }
}
.l21 {
    @media (min-width: 680px) {
        grid-template-columns: 2fr 3fr;
    }
    > div:nth-child(3n+1) {
        @media (min-width: 680px) {
            grid-column: span 2;
        }
    }
}
.l3 {
    @media (min-width: 560px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.l4 {
    @media (min-width: 560px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1025px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
.l41 {
    @media (min-width: 560px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        > div:nth-child(3n+1) {
            grid-column: span 2;
        }
    }
    @media (min-width: 1025px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        > div:nth-child(3n+1) {
            grid-column: 1;
        }
        > div:nth-child(4n+1) {
            grid-column: span 2;
        }
        > div:nth-child(12n-1) {
            grid-column: span 2;
        }
    }
}
.l42 {
    @media (min-width: 365px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1025px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
